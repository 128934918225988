.frieze {
    padding: 6.4rem 0 0;
    position: relative;

    @include media-breakpoint-down(md) {
        padding: 3.2rem 0 0;
    }

    &::before {
        background: var(--ecume-125);
        bottom: 0;
        content: '';
        display: block;
        left: 50%;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        width: .2rem;

        .block-bg-dark & {
            background: var(--bs-white);
        }
    }

    &-wrapper {
        margin-bottom: 13.6rem;

        @include media-breakpoint-down(md) {
            margin-bottom: 6.4rem;
        }
    }

    &-list {
        li {
            &:nth-child(even) {
                .frieze {
                    .col-text {
                        order: 2;

                        @include media-breakpoint-up(md) {
                            padding: 0 0 0 3.2rem;
                        }
                    }

                    .col-image {
                        order: 1;

                        @include media-breakpoint-up(md) {
                            padding: 0 .8rem 0 0;
                        }
                    }
                }
            }

            &:first-child {
                .frieze {
                    @include media-breakpoint-down(md) {
                        padding: 6.4rem 0 0;
                    }
                }
            }

            &:last-child {
                .frieze {
                    &::before {
                        bottom: -12rem;

                        @include media-breakpoint-down(md) {
                            bottom: -6.4rem;
                        }
                    }
                }
            }
        }
    }

    .col-text {
        padding: 0 3.2rem 0 0;

        @include media-breakpoint-down(md) {
            order: 2;
            padding: 0;
        }

        .card {
            &-inca {
                @include media-breakpoint-down(md) {
                    --bs-border-radius: 0 0 2.4rem .4rem;
                }
            }

            &-body {
                @include media-breakpoint-down(md) {
                    padding: 1.4rem 1.6rem 4.8rem;
                }

                .block-bg-dark & {
                    * {
                        color: var(--ecume-125);
                    }
                }

                .material-symbols-outlined {
                    &:last-child {
                        @include media-breakpoint-down(md) {
                            bottom: 1.4rem;
                            right: 1.4rem;
                        }
                    }
                }
            }

            &-title {
                @include media-breakpoint-down(md) {
                    font-size: 2.4rem;
                }
            }

            &-end {
                @include media-breakpoint-down(md) {
                    font-size: 1.6rem;
                }
            }
        }
    }

    .col-image {
        padding: 0 0 0 .6rem;

        @include media-breakpoint-down(md) {
            order: 1;
            padding: 0;
        }

        .frieze-img {
            position: relative;

            img {
                aspect-ratio: 1.2;
                border-radius: 2.4rem .4rem;

                @include media-breakpoint-down(md) {
                    border-radius: 2.4rem .4rem 0 0;
                }
            }
        }
    }
}
