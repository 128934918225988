.richtext {
    margin: 13.6rem 0 4.8rem;

    @include media-breakpoint-down(md) {
        margin: 6.4rem 0 1.6rem;
    }

    &.block-bg-dark {
        margin: 0;
        padding: 13.6rem 0 4.8rem;

        @include media-breakpoint-down(md) {
            padding: 6.4rem 0 1.6rem;
        }
    }
}
