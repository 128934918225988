@import '@accessible360/accessible-slick/slick/slick';
@import '@accessible360/accessible-slick/slick/accessible-slick-theme';

.slick-slider {
    margin-bottom: 2.4rem;

    .slick-list {
        padding: 0 1.6rem 0 0 !important;
    }

    .slick-slide {
        padding: 0 0 0 .8rem;

        &:not(.slick-active) {
            opacity: .6;
        }

        > div {
            height: 100%;

            [class*=col-] {
                height: 100%;

                .card-inca {
                    height: 100%;
                }
            }
        }
    }

    .slick-arrow {
        bottom: -5rem;
        color: var(--ecume-125);
        height: 3.4rem;
        top: auto;
        transform: translateX(-50%);
        width: 3.4rem;

        &.slick-next {
            left: calc(50% + 5rem);
        }

        &.slick-prev {
            left: calc(50% - 5rem);
        }

        .material-symbols-outlined {
            font-size: 3.4rem;
        }
    }

    + .slider-paging {
        display: flex;
        font-size: 1.4rem;
        justify-content: center;
        line-height: normal;
    }
}

.slider-pub {
    width: 100%;

    &:not(.slick-slider) {
        display: flex;
        overflow: hidden;

        .slider-pub-item {
            flex: 0 0 54.4rem;

            @include media-breakpoint-down(md) {
                flex: 0 0 100%;
            }
        }
    }

    .slick-list {
        padding: 0 !important;
    }

    .slick-track {
        display: flex;
    }

    &-wrapper {
        .slick-slider .slick-list {
            @include media-breakpoint-up(xl) {
                padding-right: 0 !important;
            }

        }

        .slick-active {
            opacity: 1 !important;
        }

        .slick-slide {
            //max-height: 51.5rem;

            @include media-breakpoint-up(md) {
                //max-height: 33.5rem;
            }

        }
    }

    &-item {
        display: flex !important;
        padding: 1rem 0;
        max-width: 54.4rem;
        width: 54.4rem;
        margin-right: 1.6rem;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            width: 100%;
        }

        a {
            text-decoration: none;
        }
    }

    &-image {
        flex-shrink: 0;
        background: $gray-300;
        width: 21rem;

        @include media-breakpoint-down(md) {
            margin: 0 auto 1.6rem;
        }

        img {
            width: 100%;
        }
    }

    &-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .incaicon {
            font-size: 2.4rem;
            margin-left: .5rem;
            vertical-align: middle;
        }

        .slider-pub-title {
            .like-h4 {
                font-size: 2.4rem;
                line-height: 1.25;

                @include media-breakpoint-up(md) {
                    font-size: 1.8rem;
                    line-height: 1.33;
                }
            }
        }
    }
}
