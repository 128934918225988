.fr-content-media {
    img {
        border-radius: .4rem .4rem 2.4rem;
    }

    &__caption {
        padding-top: .8rem;

        p {
            font-weight: 400;
            font-size: 1.4rem;
        }
    }
}
