.push {
    &-wrapper {
        .row {
            --bs-gutter-x: 3.2rem;

            gap: 3.2rem 0;

            > [class$='-6'] {
                .landing-page__block, .push, .card, .container {
                    height: 100%;
                }

                .card {
                    flex-direction: column;

                    &-title {
                        @include media-breakpoint-up(md) {
                            font-size: 1.8rem;
                        }
                    }
                }
            }
        }
    }

    &.block-bg-dark {
        .card-inca-horizontal {
            --bs-card-bg: var(--ecume-125);
            --bs-border-radius: 0;

            border: 0;
            padding: 2.4rem 0;

            @include media-breakpoint-down(md) {
                padding: 6rem 0;
            }
            * {
                color: var(--bs-white);
            }

            .card-body {
                padding: 0 2.4rem 7.2rem;

                @include media-breakpoint-down(md) {
                    padding: 2.4rem 1.6rem 7.2rem;
                }

                .material-symbols-outlined {
                    bottom: 4.8rem;

                    @include media-breakpoint-down(md) {
                        bottom: 8.4rem;
                        right: 1.6rem;
                    }
                }

                .btn {
                    --bs-btn-border-color: var(--bs-white);
                    color: var(--bs-white);

                    &:hover, &:focus {
                        --bs-btn-hover-border-color: var(--bs-white);
                        --bs-btn-hover-bg: var(--ecume-200);
                        --bs-btn-hover-color: var(--bs-white);
                    }
                }
            }

            .card-title {
                color: var(--bs-white);
                font-size: 3.2rem;

                @include media-breakpoint-down(md) {
                    font-size: 2.4rem;
                    margin: 0 0 2.4rem;
                }
            }
        }
    }

    .card-inca {
        .card-body {
            &-w-btn {
                align-self: flex-start;
                padding: 3.8rem 2.4rem 4.6rem 3rem;
                max-width: calc(100% - 14.5rem);

                @include media-breakpoint-down(md) {
                    max-width: 100%;
                }

                @include media-breakpoint-down(md) {
                    padding: 2.2rem 2.2rem 3rem;
                }

                .card-end {
                    @include media-breakpoint-down(md) {
                        font-size: 1.6rem;
                    }
                }

                .btn {
                    align-self: center;
                    margin: 2.4rem 0 0;

                    @include media-breakpoint-up(md) {
                        bottom: 4.6rem;
                        margin: 0;
                        position: absolute;
                        right: 3rem;
                    }
                }
            }
        }
    }
}

.w848 {
    .push-wrapper {
        margin-top: 0;

        .container {
            padding-left: 0;
            padding-right: 0;
        }

        .row {
            margin-left: -.8rem;
            margin-right: -.8rem;

            @include media-breakpoint-down(md) {
                margin-left: 0;
                margin-right: 0;
            }

            > * {
                padding-left: .8rem;
                padding-right: .8rem;

                @include media-breakpoint-down(md) {
                    padding-left: 1.6rem;
                    padding-right: 1.6rem;
                }
            }
        }
    }
}
