.quote-block {

    &-inner {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 0;

        @include media-breakpoint-down(lg) {
            flex-direction: column;
        }
    }

    &-media {
        flex-shrink: 0;
        width: 50%;
        @include media-breakpoint-down(lg) {
            width: 100%;
        }

        img {
            border-radius: .4rem .4rem 2.4rem;
            width: 100%;
            object-fit: cover;

            @include media-breakpoint-down(lg) {
                border-radius: 2.4rem .4rem;
            }
        }

        .enhancedimage--wrapper {
            position: relative;

            &::after {
                height: 100%;
                width: .2rem;
                content: '';
                position: absolute;
                right: 2.4rem;
                top: 0;
                background: var(--bs-white);
            }
        }
    }

    &-info {
        width: 50%;
        max-width: 67.2rem;
        position: relative;
        padding: 2.4rem 0 2.4rem 4.8rem;

        @include media-breakpoint-down(lg) {
            max-width: 100%;
            width: 100%;
            padding-left: 4.3rem;
        }

        &.no-image {
            padding-right: 2.4rem;
            padding-top: 3.2rem;
            max-width: 69.6rem;
        }

        &::before {
            background: var(--ecume-360);
            content: '';
            position: absolute;
            display: block;

            @include media-breakpoint-up(lg) {
                height: .2rem;
                left: 0;
                right: 0;
            }

            @include media-breakpoint-down(lg) {
                width: .2rem;
                left: 1.6rem;
                top: 0;
                bottom: 0;
            }
        }
    }

    &-subtitle {
        font-size: 1.4rem;
        line-height: 1.3;
        @include media-breakpoint-down(sm) {
            font-size: 1.2rem;
        }
    }

    &-text {
        font-size: 3.2rem;
        line-height: 1.2;
        font-weight: 800;
        position: relative;

        @include media-breakpoint-down(sm) {
            font-size: 2.4rem;
        }

        &__inner {
            position: relative;
            z-index: 1;
        }

        &__decor {
            font-size: 18.3rem;
            line-height: 7.3rem;
            height: 7.3rem;
            position: absolute;
            top: -5.4rem;
            left: -6.8rem;
            color: var(--emeraude-850);

            @include media-breakpoint-up(lg) {
                .no-image & {
                    top: -1.7rem;
                }
            }

            @include media-breakpoint-down(lg) {
                left: -2.8rem;
            }

            .block-bg-dark & {
                color: var(--ecume-360);
            }

            &::before {
                content: '«';
                position: absolute;
                top: .5rem;
            }

        }
    }

    &-link {
        a {
            text-decoration: none;
        }

        &__text {
            font-weight: 800;
        }
        .ibg {
            vertical-align: middle;
            margin-left: .8rem;

            .block-bg-dark & {
                color: var(--ecume-125);
            }
        }
    }
}

.block-bg-dark blockquote, blockquote.block-bg-dark {
    &::before {
        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTAiIGhlaWdodD0iNzQiIHZpZXdCb3g9IjAgMCA5MCA3NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQ4Ljc4ODUgNzMuODI4NkwyNS41MDMxIDM3LjQ2OEw0OC43ODg1IDBIMjMuNjU1TDAgMzcuNjUyNkwyMy42NTUgNzMuODI4Nkg0OC43ODg1Wk05MCA3My44Mjg2TDY2LjcxNDYgMzcuNDY4TDkwIDBINjQuODY2NUw0MS4yMTE1IDM3LjY1MjZMNjQuODY2NSA3My44Mjg2SDkwWiIgZmlsbD0iIzNENTM5QiIvPgo8L3N2Zz4K')!important;
    }
}
