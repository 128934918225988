// Fonts
$font-size-base: 1.6rem;
$font-size-sm: 1.6rem;
$font-size-lg: 1.6rem;
$font-family-sans-serif: $font-secondary;
$font-family-base: $font-primary;
$line-height-base: 1.625;
$line-height-sm: 1.44;
$line-height-lg: 2;

// Typography
$h1-font-size: $font-size-base * 3;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

// scss-docs-start display-headings
$display-font-sizes: (
    1: 5.6rem,
    2: 4.2rem,
    3: 3rem,
    4: 2.4rem,
    5: 2rem,
    6: 1.6rem
);

/**
colors
*/
$body-color: $ecume-125;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1400px
);
// scss-docs-end grid-breakpoints


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1360px
);
// scss-docs-end container-max-widths


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 3.2rem;
$grid-row-columns: 6;

// Container padding

$container-padding-x: $grid-gutter-width;

$zindex-modal: 9999999;
