.calendar-rebound {
    margin: 13.6rem 0;

    @include media-breakpoint-down(md) {
        margin: 6.4rem 0;
    }

    &-list {
        @include media-breakpoint-up(xl) {
            --bs-gutter-x: 4rem;
        }

        &.slick-slider {
            display: flex;
        }

        &:not(.slick-slider) {
            @include media-breakpoint-down(md) {
                display: flex;
                flex-wrap: nowrap;
                overflow: hidden;
            }
        }

        .card-inca {
            @include media-breakpoint-up(md) {
                height: 100%;
            }

            .card-body {
                align-self: flex-start;
            }

            .date, .location {
                align-items: flex-start;
                display: flex;
                gap: 0 .8rem;

                .incaicon {
                    font-size: 2rem;
                    margin-top: .5rem;

                    @include media-breakpoint-down(md) {
                        margin-top: .3rem;
                    }
                }
            }

            .date {
                margin-bottom: .8rem;

                + p {
                    margin-top: 1.6rem;
                }
            }

            .location {
                margin-bottom: 1.6rem;
            }
        }
    }
}
