.list-block {
    margin: 6.4rem 0;

    &-title {
        font-size: 2.4rem;

        @include media-breakpoint-down(md) {
            font-size: 1.8rem;
            line-height: 1.3333;
        }
    }

    &-inner {
        align-items: center;
        border-bottom: .2rem solid var(--ecume-360);
        display: flex;
        gap: 0 .8rem;
        justify-content: space-between;
        padding: 1.6rem 0;

        .btn {
            @include media-breakpoint-down(md) {
                border-radius: 50%;
            }

            .incaicon {
                font-size: 2.4rem;
            }
        }
    }

    &-info {
        @include media-breakpoint-down(md) {
            font-size: 1.4rem;
        }

        .title {
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 1.625;
            margin: 0;

            @include media-breakpoint-down(md) {
                font-size: 1.4rem;
                line-height: 1.57;
            }
        }

        .doc-size {
            color: rgba(var(--ecume-125-rgb), .5);
            margin: 0;

            .format {
                text-transform: uppercase;
            }
        }

        .ressource-link {
            font-weight: 800;
            text-decoration: none;

            &:hover, &:focus {
                text-decoration: underline;
            }

            &[target='_blank']:not(.btn) {
                .list-block & {
                    &::after {
                        content: $incaicon-arrow_blank;
                        font-family: $icomoon-font-family;
                        padding-left: .5rem;
                        font-size: 80%;
                    }
                }
            }

            .incaicon {
                margin-left: .4rem;
            }
        }

        .last-num-link {
            text-decoration: underline;
        }
    }
}
