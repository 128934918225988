/**
Font family
*/
$font-primary: 'Marianne', 'Arial', sans-serif;
$font-secondary: 'Marianne', 'Arial', sans-serif;

/**
Colors
*/
$white: #fff;
$black: #000;
$ecume-125: #17203b;
$ecume-125-hover: #212d53;
$ecume-200: #233164;
$ecume-charte: #2f4077;
$ecume-360: #3d539b;
$ecume-975: #eff2fd;
$emeraude-charte: #34cb6a;
$emeraude-850: #69e2a1;
$emeraude-850-darker: #279d59;
$emeraude-900: #a6f2cf;

// scss-docs-start color-variables
$blue: #526de4;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #9d2727;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #0e893e;
$teal: #20c997;
$cyan: #0dcaf0;
// scss-docs-end color-variables

// scss-docs-start gray-color-variables
$gray-100: #f5f5f5;
$gray-200: #f0f0f0;
$gray-300: #ebebeb;
$gray-400: #e5e5e5;
$gray-500: #ccc;
$gray-600: #999;
$gray-700: #666;
$gray-800: #343a40;
$gray-850: #222940;
$gray-900: #212529;
// scss-docs-end gray-color-variables

// scss-docs-start theme-color-variables
$primary: $ecume-125;
$secondary: $emeraude-850;
$success: $green;
$info: $blue;
$warning: $yellow;
$danger: $red;
$light: $white;
$dark: $gray-900;
// scss-docs-end theme-color-variables


// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1.6rem;
$spacers: (
    0: 0,
    1: $spacer * .5,
    2: $spacer * 1,
    3: $spacer * 1.5,
    4: $spacer * 2,
    5: $spacer * 3,
    6: $spacer * 4,
    7: $spacer * 6,
    8: $spacer * 8.5,
);
// scss-docs-end spacer-variables-maps


$btn-color: $white;
$btn-border-radius: 2.7rem;
$btn-font-size: 1.6rem;
$btn-line-height: 1.2 ;
$btn-font-weight: 600;
$btn-link-color: $ecume-125;
$btn-link-hover-color: $ecume-125-hover;
$btn-padding-y: 1.6rem;
$btn-padding-x: 1.6rem;
