.read-more {
    margin: 13.6rem 0;

    @include media-breakpoint-down(md) {
        margin: 6.4rem 0;
    }

    &-list {
        &.slick-slider {
            display: flex;

            @include media-breakpoint-down(md) {
                margin-left: 0;
                margin-right: 0;

                .slick-list {
                    padding: 0 !important;
                }

                .slick-slide {
                    padding: 0 !important;
                }
            }
        }

        &:not(.slick-slider) {
            @include media-breakpoint-down(md) {
                display: flex;
                flex-wrap: nowrap;
                overflow: hidden;
            }
        }

        .card-inca {
            @include media-breakpoint-up(md) {
                height: 100%;
            }

            .card-body {
                align-self: flex-start;
            }
        }
    }
}
