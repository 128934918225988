.hp-profile-block {
    margin-top: -1.5rem;

    &-inner {
        align-items: center;
        position: relative;

        @include media-breakpoint-up(md) {
            padding-bottom: .4rem;
        }

        &::after {
            @include media-breakpoint-up(md) {
                left: 1.6rem;
                right: 1.6rem;
                height: .4rem;
                position: absolute;
                bottom: 0;
                background: var(--ecume-360);
                display: block;
                content: '';
            }
        }
    }

    &-media {
        @include media-breakpoint-down(sm) {
            margin: 0 -1.6rem;
        }

        img {
            border-radius: 2.4rem .4rem 0 0;
            width: 100%;
            object-fit: cover;
        }
    }
}
