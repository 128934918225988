.media-full-block {
    width: 100%;

    &-inner {
        position: relative;
    }

    &-media {
        height: 56rem;

        @media screen and (min-width: 1920px) {
            height: 71rem;
        }

        @include media-breakpoint-down(md) {
            height: 46.4rem;
            position: relative;
        }

        &::before {
            @include media-breakpoint-down(md) {
                background-image: linear-gradient(0deg, rgba(var(--ecume-125), .8) 34.91%, rgba(var(--bs-gray-850), .12) 70.26%);
                bottom: 0;
                border-radius: 2.4rem 0;
                content: '';
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 1;
            }
        }

        picture {
            height: 100%;
        }

        img {
            aspect-ratio: 2.7;
            border-radius: 2rem .2rem 2rem 0;
            height: 100%;
            object-fit: cover;
            width: 100%;

            @include media-breakpoint-down(md) {
                aspect-ratio: .769;
                border-radius: 2.4rem 0;
            }

            .hp-borderless ~ main & {
                border-radius: 0 0 2rem;

                @include media-breakpoint-down(md) {
                    border-radius: 0 0 2.4rem;
                }
            }
        }

        .video {
            border-radius: 2rem .2rem 2rem 0;
            margin: 0;
            overflow: hidden;

            &__content {
                max-width: none;
            }

            .btn {
                left: auto;
                right: 3.2rem;
                top: 3.2rem;
                transform: none;

                .material-symbols-outlined {
                    font-size: 1.8rem;
                }
            }
        }
    }

    &-info {
        background: var(--bs-white);
        display: flex;
        flex-direction: column;
        padding: 3rem 4rem 4rem;
        position: relative;
        width: 100%;
        z-index: 1;

        @include media-breakpoint-up(md) {
            border-top: 1rem solid var(--ecume-360);
            margin: -14rem 0 0;
        }

        @include media-breakpoint-down(md) {
            background: transparent;
            bottom: 0;
            color: var(--bs-white);
            left: 0;
            padding: 0 1.6rem 2.4rem;
            position: absolute;
            z-index: 2;
        }

        .border-bg {
            background: url('../../../images/media-full-block-border.svg') no-repeat 0 0;
            display: block;
            height: 64.8rem;
            left: -24.8rem;
            position: absolute;
            top: 5.4rem;
            width: 22.8rem;

            @include media-breakpoint-down(xxl) {
                display: none;
            }
        }
    }

    &-title {
        line-height: 1.125;
        order: 2;
    }

    &-surtitle {
        font-size: 2.4rem;
        font-weight: 800;
        line-height: 1.25;
        order: 1;

        @include media-breakpoint-down(md) {
            font-size: 1.6rem;
            font-weight: 400;
            line-height: 1.75;
        }
    }

    &-text {
        order: 3;
    }
}
