.multiple-articles {
    &.block-bg-dark {
        > .card-body * {
            color: var(--ecume-125);
        }

        .col-aside {
            * {
                color: var(--ecume-125);
            }
        }
    }

    .row {
        --bs-gutter-x: 1.6rem;

        @include media-breakpoint-down(md) {
            gap: 1.6rem;
        }

        > [class*=col-] {
            &:first-child {
                .card {
                    height: 51.6rem;

                    @include media-breakpoint-down(lg) {
                        height: 100%;
                    }

                    @include media-breakpoint-down(md) {
                        --bs-card-border-width: .2rem;
                        min-height: 22rem;
                    }
                }
            }
        }
    }

    .col-aside {
        .card-body {
            @include media-breakpoint-down(md) {
                padding: 2.2rem 1.4rem 3rem;
            }

            .material-symbols-outlined:last-child {
                @include media-breakpoint-down(md) {
                    bottom: 3.2rem;
                }
            }
        }

        > ul {
            display: grid;
            gap: 2.4rem;
            margin: 0;

            @include media-breakpoint-down(md) {
                gap: 1.6rem;
            }
        }
    }
}
