$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "fonts" !default;

$incaicon-edit: unquote('"\\e91a"');
$incaicon-basket: unquote('"\\e919"');
$incaicon-user: unquote('"\\e918"');
$incaicon-fight: unquote('"\\e912"');
$incaicon-etiology: unquote('"\\e913"');
$incaicon-treatment: unquote('"\\e914"');
$incaicon-diagnostic: unquote('"\\e915"');
$incaicon-menu-2: unquote('"\\e911"');
$incaicon-close: unquote('"\\e90c"');
$incaicon-angle-right: unquote('"\\e90b"');
$incaicon-date_range: unquote('"\\e909"');
$incaicon-location_pin: unquote('"\\e90a"');
$incaicon-arrow_blank: unquote('"\\e907"');
$incaicon-instagram: unquote('"\\e903"');
$incaicon-linkedin: unquote('"\\e904"');
$incaicon-youtube: unquote('"\\e905"');
$incaicon-x: unquote('"\\e906"');
$incaicon-facebook: unquote('"\\e902"');
$incaicon-search: unquote('"\\e901"');
$incaicon-menu: unquote('"\\e900"');
$incaicon-file_download: unquote('"\\e908"');
$incaicon-share: unquote('"\\e90d"');
$incaicon-mail_outline: unquote('"\\e90e"');
$incaicon-stay_current_portrait: unquote('"\\e90f"');
$incaicon-phone: unquote('"\\e910"');
$incaicon-prevention: unquote('"\\e916"');
$incaicon-biology: unquote('"\\e917"');

