.references-block {

    border-radius: .4rem .4rem 2.4rem;
    h4 {
        font-weight: 800;
    }

    &.block-bg-dark {
        @include media-breakpoint-down(sm) {
            margin-left: -1.6rem;
            margin-right: -1.6rem;
        }

        ul {
            li {
                &::before {
                    background: var(--bs-white);
                }
            }
        }
    }

    > * {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .wysiwyg & {
        .h3, h3 {
            margin-top: 0;
        }
    }
}
