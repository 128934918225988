.half-overlapped {
    max-width: 56.2rem;

    &-wrapper {
        margin: 13.6rem 0 0;

        @include media-breakpoint-down(md) {
            margin: 3.2rem 0;
        }

        &.block-bg-dark {
            padding: 13.6rem 0;
        }

        .container {
            >  .row {
                gap: 6.4rem 0;

                @include media-breakpoint-up(xxl) {
                    --bs-gutter-x: 11rem;
                }

                @include media-breakpoint-only(lg) {
                    --bs-gutter-x: 11rem;
                }
            }
        }

        .half-overlapped {
            margin: 0;
            padding: 0;
            max-width: 100%;
        }
    }

    [class*='col-'] {
        &.order-1 {
            max-width: 32rem;

            @include media-breakpoint-down(xl) {
                margin-bottom: -13.6rem;
                max-width: 100%;
            }
        }

        &.order-2 {
            @include media-breakpoint-up(xxl) {
                width: calc(50% + .9rem);
            }
        }
    }

    .card-inca {
        background: rgba(var(--bs-white-rgb), .97);
        height: 40.8rem;
        overflow: hidden;

        @include media-breakpoint-down(xl) {
            background: var(--bs-white);
            height: auto;
            margin: 0 1.6rem;
        }

        @include media-breakpoint-down(lg) {
            // --bs-card-border-width: .1rem;
        }

        .card-body {
            align-self: flex-start;

            .card-title {
                @include media-breakpoint-down(xl) {
                    font-size: 1.8rem;
                }
            }

            .card-end {
                @include media-breakpoint-down(xl) {
                    font-size: 1.4rem;
                }
            }

            .material-symbols-outlined {
                position: absolute;
            }
        }
    }

    img {
        aspect-ratio: .67;
        border-radius: 2.4rem .4rem;
        height: 100%;
        object-fit: cover;
        width: 100%;

    }
}
