.text-image {
    &.block {
        margin-top: 6.4rem;

        @include media-breakpoint-down(md) {
            margin-top: 3.2rem;
        }
    }

    &.block-bg-dark {
        padding: 13.6rem 0;

        &.block {
            margin: 0;
            padding-top: 6.4rem;

            @include media-breakpoint-down(md) {
                padding-top: 3.2rem;
            }
        }

        .card-inca {
            background: var(--ecume-125);

            * {
                color: var(--bs-white);
            }
        }
    }

    &.text-image-w-podcast {
        &.block-bg-dark {
            .btn-link {
                &:hover, &:focus {
                    color: var(--bs-white);

                    .material-symbols-outlined {
                        background: var(--ecume-200);
                        border-radius: 50%;
                    }
                }
            }
        }

        .card-inca {
            @include media-breakpoint-up(lg) {
                margin-top: 1.6rem;
            }

            .card {
                &-body {
                    padding: 1.4em 2.4rem 4rem 0;

                    @include media-breakpoint-down(lg) {
                        padding: 2.4em 0 1.6rem 2.2rem;
                    }
                }
            }
        }

        .podcast-wrapper {
            @include media-breakpoint-down(lg) {
                border-left: .2rem solid var(--ecume-360);
                margin-left: 1.6rem;
                padding: 0 0 .8rem 2.4rem;
            }
        }

        .transcription {
            margin-top: .8rem;

            .btn {
                margin-bottom: 1.6rem;
            }
        }

        .text-image-img {
            img {
                border-radius: 2.4rem .4rem;
            }
        }
    }

    .container {
        @include media-breakpoint-down(md) {
            padding: 0;
        }

        .row {
            @include media-breakpoint-down(lg) {
                flex-direction: column-reverse;
            }
        }
    }

    .card-inca {
        --bs-border-radius: 0;
        border-top-width: .2rem;
        margin-top: 6rem;

        @include media-breakpoint-up(xxl) {
            margin-right: -.8rem;
        }

        @include media-breakpoint-down(lg) {
            border-left-width: .2rem;
            border-top-width: 0;
            margin: 0 1.6rem;
        }

        .card {
            &-title {
                font-size: 4.8rem;
                line-height: 1.125;

                @include media-breakpoint-down(xl) {
                    font-size: 3.8rem;
                }

                @include media-breakpoint-down(md) {
                    font-size: 2.8rem;
                    letter-spacing: .084rem;
                }
            }

            &-body {
                padding: 2.2rem 2.4rem 1.6rem;

                @include media-breakpoint-down(md) {
                    padding: 2.4rem 0 2.4rem 2.2rem;
                }
            }
        }
    }

    &-img {
        position: relative;

        @include media-breakpoint-up(xxl) {
            padding-left: .8rem;
        }

        img {
            aspect-ratio: 1.2;
            border-radius: .4rem .4rem 2.4rem;

            @include media-breakpoint-down(md) {
                aspect-ratio: 1.27;
            }
        }
    }

    .video-block {
        padding: 0;

        @include media-breakpoint-up(xxl) {
            padding-left: .8rem;
        }

        &-media {
            margin-bottom: 0 !important;

            figure {
                margin: 0;
            }
        }
    }
}
