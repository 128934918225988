.video-block {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 110.4rem;
    padding: 2.8rem 0;

    @include media-breakpoint-down(md) {
        max-width: 76.8rem;
        position: relative;
    }

    &-wrapper {
        margin-top: 10.8rem;

        @include media-breakpoint-down(md) {
            margin-top: 3.6rem;
        }

        &.block-bg-dark {
            margin-top: 0;
        }
    }

    &-inner {
        position: relative;
    }

    &-media {
        overflow: hidden;
        border-radius: 0 0 2rem;

        .plyr {
            &__control {
                &--overlaid {
                    width: 8rem;
                    height: 8rem;
                    display: flex!important;
                    justify-content: center;
                    align-items: center;
                    background: var(--emeraude-850);
                    border: 1px solid var(--ecume-125);

                    &::before {
                        width: 4.667rem;
                        height: 4.667rem;
                        border: .5rem solid var(--ecume-125);
                        border-radius: 50%;
                        position: absolute;
                        content: '';
                    }

                    svg {
                        fill: var(--ecume-125);
                    }
                }
            }
        }
    }

    &-info {
        max-width: 65.6rem;

        .btn-link {
            text-decoration: none;

            .text-white & {
                color: white;
            }

            &:hover, &:focus-visible {
                .block-bg-dark & {
                    color: var(--bs-white);
                }
            }

            &:focus-visible {
                .block-bg-dark & {
                    outline: .2rem solid var(--bs-white);
                    outline-offset: .4rem;
                }
            }

            &:hover {
                .text {
                    text-decoration: underline;
                }
            }

            span {
                vertical-align: middle;
            }
        }
    }
}
