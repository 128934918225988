@charset "UTF-8";
/**
Font family
*/
/**
Colors
*/
/**
colors
*/
.card-inca {
  --bs-card-border-color: var(--ecume-360);
  --bs-border-radius: .4rem .4rem 2.4rem .4rem;
  --bs-card-border-width: 0;
  border-top-width: 1rem;
}
.card-inca:hover .material-symbols-outlined:last-child.ibg, .card-inca:focus .material-symbols-outlined:last-child.ibg {
  background: var(--emeraude-900);
}
.card-inca:hover .material-symbols-outlined:last-child.text-primary, .card-inca:focus .material-symbols-outlined:last-child.text-primary {
  color: var(--ecume-125--hover) !important;
}
.card-inca-w-border {
  border-bottom-width: 0.2rem;
  border-left-width: 0.2rem;
  border-right-width: 0.2rem;
}
.card-inca-w-border .card-body {
  padding: 3rem 2.4rem 3.8rem;
}
.card-inca-w-border .card-body .material-symbols-outlined:last-child {
  bottom: 3.8rem;
  position: absolute;
  right: 2.4rem;
}
.card-inca-w-border .card-title {
  padding-right: 3.2rem;
}
.card-inca-no-border {
  border-top: none;
  position: relative;
}
.card-inca-no-border::after {
  right: 0;
  left: 0;
  top: 0;
  content: "";
  position: absolute;
  display: block;
  background: var(--ecume-360);
  height: 0.2rem;
}
.card-inca-no-border--bottom::after {
  top: initial;
  bottom: 0;
}
@media (min-width: 768px) {
  .card-inca-horizontal {
    flex-direction: row;
  }
}
.card-inca-horizontal .card-body {
  align-self: center;
  max-width: 100%;
  order: 2;
  padding: 2.4rem 2.4rem 7.2rem;
}
@media (max-width: 767.98px) {
  .card-inca-horizontal .card-body {
    max-width: none;
    padding: 2.4rem 1.6rem 6.2rem;
  }
  .list-articles-item .card-inca-horizontal .card-body {
    padding-bottom: 1.6rem;
  }
}
.push .card-inca-horizontal .card-body {
  align-self: flex-start;
}
.list-articles-item .card-inca-horizontal .card-body {
  max-width: 100%;
}
.card-inca-horizontal .card-body .card-title {
  font-size: 2.4rem;
  margin: 0 0 1.6rem;
  padding: 0;
}
@media (max-width: 767.98px) {
  .card-inca-horizontal .card-body .card-title {
    font-size: 1.8rem;
  }
}
@media (min-width: 992px) {
  .list-articles-item .card-inca-horizontal .card-body .card-title {
    max-width: 73.7rem;
  }
}
.card-inca-horizontal .card-body .card-end {
  max-width: 73.7rem;
}
@media (max-width: 767.98px) {
  .card-inca-horizontal .card-body .card-end {
    font-size: 1.4rem;
  }
  .block-bg-dark .card-inca-horizontal .card-body .card-end {
    font-size: 1.6rem;
  }
}
@media (min-width: 992px) {
  .list-articles-item .card-inca-horizontal .card-body .card-end {
    max-width: 73.7rem;
  }
}
.card-inca-horizontal .card-body .card-end p {
  margin: 0;
}
.card-inca-horizontal .card-body .material-symbols-outlined:last-child:not(.position-relative) {
  bottom: 2.4rem;
  right: 2.4rem;
}
.card-inca-horizontal .card-header {
  background: none;
  order: 1;
  padding: 0;
}
@media (min-width: 768px) {
  .card-inca-horizontal .card-header {
    flex: 0 0 32.4rem;
  }
  .block-bg-dark .card-inca-horizontal .card-header {
    flex: 0 0 43.2rem;
  }
}
.block-bg-dark .card-inca-horizontal .card-header {
  padding: 0 0 0 1.6rem;
}
@media (max-width: 767.98px) {
  .block-bg-dark .card-inca-horizontal .card-header {
    padding: 0;
  }
}
@media (min-width: 768px) {
  .card-inca-horizontal .card-header picture {
    height: 100%;
  }
}
.card-inca-horizontal .card-header img {
  aspect-ratio: 1.5;
  border-radius: 0 0 2.4rem;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
  position: relative;
  z-index: 1;
}
.block-bg-dark .card-inca-horizontal .card-header img {
  border-radius: 0;
}
.card-inca-horizontal.card-inca-pub .card-header img {
  aspect-ratio: auto;
  border-radius: 0;
}
.card-inca-horizontal.card-inca-pub::after {
  z-index: 1;
}
.card-inca-light {
  border-width: 0.2rem;
  min-height: 9.2rem;
  padding: 1.6rem;
}
.card-inca-light:hover {
  background: var(--ecume-975);
}
.card-inca-light-wrapper .row {
  --bs-gutter-x: 1.8rem;
  gap: 1.8rem 0;
  margin: 0;
}
.row .card-inca-light-wrapper .row {
  margin: 0 -0.9rem;
}
.card-inca-light.card-inca .card-body {
  flex-direction: revert;
  gap: 0 0.8rem;
  justify-content: space-between;
  padding: 0;
}
.card-inca-light.card-inca .card-body .card-title {
  font-size: 1.6rem;
  font-weight: 700;
  margin: 0;
}
.card-inca-light.card-inca .card-body .card-title a {
  text-decoration: none;
}
.card-inca-light.card-inca .card-body .material-symbols-outlined:last-child {
  align-self: flex-start;
  margin: 0;
}
.card-inca-contact {
  margin: 6.4rem auto 0;
  max-width: 65.6rem;
}
.card-inca-contact .card-body {
  padding: 1.4rem 2.2rem 2.2rem;
}
@media (max-width: 767.98px) {
  .card-inca-contact .card-body {
    padding: 1.4rem 1.4rem 2.2rem;
  }
}
@media (min-width: 1200px) {
  .card-inca-contact .card-body .row {
    --bs-gutter-x: 4rem;
  }
}
.card-inca-contact .card-body .row:not(:first-child) {
  margin-top: 1.6rem;
}
@media (min-width: 1200px) {
  .card-inca-contact .card-body .row [class*=col-] {
    max-width: 29.3rem;
  }
}
.card-inca-contact .card-body .card-title {
  font-size: 2.4rem;
  order: initial;
  margin: 0 0 1.5rem;
  padding: 0;
}
@media (max-width: 767.98px) {
  .card-inca-contact .card-body .card-title {
    font-size: 1.8rem;
  }
}
.card-inca-contact .card-body .title {
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 1.444;
  margin: 0;
}
.card-inca-contact .card-body .function {
  margin: 0;
}
@media (max-width: 767.98px) {
  .card-inca-contact .card-body .function {
    font-size: 1.4rem;
    margin: 0 0 1.6rem;
  }
}
.card-inca-contact .card-body .list-content {
  display: flex;
  flex-direction: column;
  gap: 0.8rem 0;
}
.card-inca-contact .card-body .list-content li {
  font-size: 1.6rem;
  line-height: normal;
  position: relative;
  padding-left: 2rem;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-all;
}
@media (max-width: 767.98px) {
  .card-inca-contact .card-body .list-content li {
    font-size: 1.4rem;
  }
}
.card-inca-contact .card-body .list-content li .incaicon {
  font-size: 1.8rem;
  margin-top: 0.4rem;
  position: absolute;
  left: 0;
  top: 0;
}
@media (max-width: 767.98px) {
  .card-inca-contact .card-body .list-content li .incaicon {
    margin-top: 0.2rem;
  }
}
.card-inca-key-figure {
  --bs-card-border-color: var(--bs-white);
  --bs-card-bg: var(--ecume-360);
  background: var(--bs-ecume-360) !important;
}
.card-inca-key-figure-wrapper {
  border-radius: 2.4rem 0.4rem;
  background: var(--ecume-360);
  padding: 2.4rem;
}
@media (max-width: 767.98px) {
  .card-inca-key-figure-wrapper {
    padding: 1.6rem;
  }
}
.card-inca-key-figure .card-body {
  padding: 3rem 2.2rem;
}
@media (max-width: 767.98px) {
  .card-inca-key-figure .card-body {
    padding: 2.2rem 2.2rem 3rem;
  }
}
.card-inca-key-figure .card-title, .card-inca-key-figure .card-start {
  color: var(--bs-white);
}
.card-inca-key-figure .card-title {
  display: flex;
  flex-direction: column;
}
.card-inca-key-figure .card-title .num {
  font-size: 6.4rem;
  letter-spacing: 0.192rem;
  line-height: 1.25;
}
.card-inca-key-figure .card-title .text {
  font-size: 3.2rem;
  line-height: normal;
}
@media (max-width: 767.98px) {
  .card-inca-key-figure .card-title .text {
    font-size: 2.4rem;
    line-height: 1.333;
  }
}
.card-inca-key-figure .card-start p {
  line-height: 1.286;
  margin: 0 0 1.6rem;
}
@media (max-width: 767.98px) {
  .card-inca-key-figure .card-start p {
    font-size: 1.2rem;
    margin: 0 0 0.8rem;
  }
}
.card-inca .card-img-overlay {
  --bs-card-img-overlay-padding: 2.4rem;
  align-items: flex-end;
  background-image: linear-gradient(180deg, rgba(var(--ecume-125-rgb), 0.05) 16.47%, rgba(var(--ecume-125-rgb), 0.8) 62.21%);
  border-radius: 0 0 2.4rem 0.4rem;
  display: flex;
}
@media (max-width: 767.98px) {
  .card-inca .card-img-overlay {
    --bs-card-img-overlay-padding: 1.6rem 1.4rem 2.2rem 1.4rem;
  }
}
.card-inca .card-img-overlay + picture {
  border-radius: 0 0 2.4rem 0.4rem;
  height: 100%;
  overflow: hidden;
}
.card-inca .card-img-overlay + picture img {
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}
.card-inca .card-img-overlay .card-body, .card-inca .card-img-overlay .card-body * {
  color: var(--bs-white);
}
.card-inca .card-img-overlay .card-body {
  padding: 0;
}
@media (max-width: 767.98px) {
  .card-inca .card-img-overlay .card-body .material-symbols-outlined:last-child {
    margin-top: 0.8rem;
  }
}
@media (max-width: 767.98px) {
  .card-inca .card-img-overlay .card-title {
    font-size: 1.6rem;
    margin-top: 0.8rem;
  }
}
@media (max-width: 767.98px) {
  .card-inca .card-img-overlay .card-title a {
    display: block;
    line-height: 1.1875;
  }
}
@media (max-width: 767.98px) {
  .card-inca .card-img-overlay .card-text {
    font-size: 1.4rem;
    line-height: 1.57;
    margin-top: 0.8rem;
  }
}
.card-inca .card-body {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}
.card-inca .card-body .material-symbols-outlined:last-child:not(.position-relative) {
  align-self: flex-end;
  color: var(--ecume-125);
  margin-top: 1.6rem;
  order: 4;
  width: 2.4rem;
}
.card-inca .card-body .btn {
  margin: 1.6rem 0 0 auto;
  order: 4;
}
.block-bg-dark .card-inca .card-body .btn {
  color: var(--ecume-125);
}
.block-bg-dark .card-inca .card-body .btn:hover, .block-bg-dark .card-inca .card-body .btn:focus {
  --bs-btn-hover-border-color: var(--ecume-125--hover);
  color: var(--ecume-125--hover);
}
.card-inca .card-title {
  font-size: 1.8rem;
  font-weight: 800;
  line-height: normal;
  margin: 1.6rem 0 0;
  order: 2;
}
.card-inca .card-title a {
  text-decoration: none;
}
.card-inca .card-text {
  margin: 1.6rem 0 0;
  order: 3;
}
.card-inca .card-start {
  align-items: center;
  display: flex;
  font-size: 1.4rem;
  justify-content: space-between;
  order: 1;
  width: 100%;
}
@media (max-width: 767.98px) {
  .card-inca .card-start {
    font-size: 1.2rem;
  }
}
.card-inca .card-start .tags-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
  line-height: normal;
  margin: 0 0 1.6rem;
}
.card-inca .card-start .tags-group .tag {
  margin: 0;
}
@media (max-width: 767.98px) {
  .card-inca .card-start .tags-group .tag {
    font-size: 1.2rem;
  }
}
.card-inca .card-end {
  order: 4;
}
.card-inca .card-header.full-img picture {
  width: 100%;
}
.card-inca .card-header.full-img img {
  width: 100%;
  object-fit: cover;
  max-height: 27.6rem;
}
.card-inca .card-text-icon .incaicon {
  flex-shrink: 0;
  margin-right: 0.8rem;
  font-size: 2rem;
}
.card-inca .card-type-badge {
  border-radius: 0.4rem 0.4rem 0.8rem 0;
  background: var(--ecume-125);
  color: var(--bs-white);
  padding: 0.8rem;
}
.card-inca-hover {
  border-radius: 0.4rem;
}
.card-inca-hover:focus-within, .card-inca-hover:hover, .card-inca-hover.active {
  background: var(--ecume-975);
}
.card-inca-call .card-body .material-symbols-outlined:last-child:not(.position-relative) {
  float: right;
}
@media (min-width: 992px) {
  .card-inca-call .card-body .material-symbols-outlined:last-child:not(.position-relative) {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 2.4rem;
  }
}
.popover .card-inca {
  font-size: 1.4rem;
  min-height: 0 !important;
}
.popover .card-inca p {
  margin-bottom: 0 !important;
}
.card-inca .text.site {
  width: calc(100% - 3.3rem);
}
.card-inca-source {
  font-size: 1.4rem;
}
@media (min-width: 992px) {
  .card-inca-trial .list-unstyled {
    width: 80%;
  }
}
.card-inca-trial .list-unstyled li {
  width: 100%;
}
@media (min-width: 992px) {
  .card-inca-trial .list-unstyled li {
    width: 50%;
    padding-right: 2rem;
  }
}
.card-inca-pub {
  min-height: 34.3rem;
}
.card-inca-pub .card-header picture {
  margin: 0 auto;
}
.card-inca-pub .card-header img {
  border-radius: 0;
  width: auto;
  min-width: 0;
  min-height: 0;
}
.card-inca-pub .card-body {
  padding: 2.4rem;
  align-self: flex-start;
}
@media (min-width: 992px) {
  .card-inca-pub .card-pub-links {
    text-align: right;
  }
}
.card-inca-pub .card-pub-links a {
  text-decoration: none;
}
.card-notice .card-title {
  order: unset;
}
.card-top-block {
  font-size: 1.4rem;
  margin-bottom: 1.6rem;
}
@media (max-width: 767.98px) {
  .card-top-block {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
  }
}
.card-top-subtitle {
  margin-right: -4.8rem !important;
  text-align: right;
}
@media (max-width: 767.98px) {
  .card-top-subtitle {
    margin-right: 0 !important;
    text-align: left;
  }
}

.card-fake-btn-absolute {
  padding-bottom: 1rem;
}
@media (min-width: 992px) {
  .card-fake-btn-absolute {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    height: 2.4rem;
    padding-bottom: 0;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}
/**
  Slider
*/
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/*=================================
  Previous and Next icon buttons
==================================*/
.slick-prev,
.slick-next {
  position: absolute;
  top: 50%;
  display: block;
  padding: 0;
  height: 20px;
  width: 20px;
  line-height: 0;
  font-size: 0;
  cursor: pointer;
  background: transparent;
  color: transparent;
  border: none;
  transform: translate(0, -50%);
}
.slick-prev:hover .slick-prev-icon,
.slick-prev:hover .slick-next-icon, .slick-prev:focus .slick-prev-icon,
.slick-prev:focus .slick-next-icon,
.slick-next:hover .slick-prev-icon,
.slick-next:hover .slick-next-icon,
.slick-next:focus .slick-prev-icon,
.slick-next:focus .slick-next-icon {
  opacity: 1;
}
.slick-prev:focus,
.slick-next:focus {
  top: calc(50% - 1px);
}
.slick-prev:focus .slick-prev-icon,
.slick-prev:focus .slick-next-icon,
.slick-next:focus .slick-prev-icon,
.slick-next:focus .slick-next-icon {
  color: orange;
  font-size: 28px;
  margin-left: -2px;
}
.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  cursor: default;
}
.slick-prev.slick-disabled .slick-prev-icon,
.slick-prev.slick-disabled .slick-next-icon,
.slick-next.slick-disabled .slick-prev-icon,
.slick-next.slick-disabled .slick-next-icon {
  opacity: 0.25;
}
.slick-prev .slick-prev-icon,
.slick-prev .slick-next-icon,
.slick-next .slick-prev-icon,
.slick-next .slick-next-icon {
  display: block;
  color: black;
  opacity: 0.75;
  font-family: "slick";
  font-size: 24px;
  line-height: 1;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-prev .slick-prev-icon:before {
  content: "←";
}
[dir=rtl] .slick-prev .slick-prev-icon:before {
  content: "→";
}

.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
.slick-next .slick-next-icon:before {
  content: "→";
}
[dir=rtl] .slick-next .slick-next-icon:before {
  content: "←";
}

/*==========================
  Slide navigation dots
===========================*/
.slick-slider {
  margin-bottom: 30px;
}
.slick-slider.slick-dotted {
  margin-bottom: 60px;
}

.slick-dots {
  position: absolute;
  bottom: -30px;
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  list-style: none;
  text-align: center;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
}
.slick-dots li button {
  display: block;
  height: 20px;
  width: 20px;
  margin-top: -4px;
  margin-left: -4px;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  border: 0;
  background: transparent;
  cursor: pointer;
}
.slick-dots li button:hover .slick-dot-icon, .slick-dots li button:focus .slick-dot-icon {
  opacity: 1;
}
.slick-dots li button:focus .slick-dot-icon:before {
  color: orange;
}
.slick-dots li button .slick-dot-icon {
  color: black;
  opacity: 0.25;
}
.slick-dots li button .slick-dot-icon:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  font-family: "slick";
  font-size: 12px;
  line-height: 1;
  text-align: center;
  transition: all 0.05s linear;
}
.slick-dots li.slick-active button:focus .slick-dot-icon {
  color: orange;
  opacity: 1;
}
.slick-dots li.slick-active button .slick-dot-icon {
  color: black;
  opacity: 1;
}
.slick-dots li.slick-active button .slick-dot-icon:before {
  margin-top: -3px;
  margin-left: -2px;
  font-size: 18px;
}

/**
 Improved .sr-only class by ffoodd: https://gist.github.com/ffoodd/000b59f431e3e64e4ce1a24d5bb36034
 */
.slick-sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

/*===========================
  Pause/play icon button
============================*/
.slick-autoplay-toggle-button {
  position: absolute;
  left: 5px;
  bottom: -32px;
  z-index: 10;
  opacity: 0.75;
  background: none;
  border: 0;
  cursor: pointer;
  color: black;
}
.slick-autoplay-toggle-button:hover, .slick-autoplay-toggle-button:focus {
  opacity: 1;
}
.slick-autoplay-toggle-button:focus {
  color: orange;
}
.slick-autoplay-toggle-button .slick-pause-icon:before {
  content: "⏸";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 18px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
}
.slick-autoplay-toggle-button .slick-play-icon:before {
  content: "▶";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 18px;
  font-weight: normal;
  line-height: 20px;
  text-align: center;
}

.slick-slider {
  margin-bottom: 2.4rem;
}
.slick-slider .slick-list {
  padding: 0 1.6rem 0 0 !important;
}
.slick-slider .slick-slide {
  padding: 0 0 0 0.8rem;
}
.slick-slider .slick-slide:not(.slick-active) {
  opacity: 0.6;
}
.slick-slider .slick-slide > div {
  height: 100%;
}
.slick-slider .slick-slide > div [class*=col-] {
  height: 100%;
}
.slick-slider .slick-slide > div [class*=col-] .card-inca {
  height: 100%;
}
.slick-slider .slick-arrow {
  bottom: -5rem;
  color: var(--ecume-125);
  height: 3.4rem;
  top: auto;
  transform: translateX(-50%);
  width: 3.4rem;
}
.slick-slider .slick-arrow.slick-next {
  left: calc(50% + 5rem);
}
.slick-slider .slick-arrow.slick-prev {
  left: calc(50% - 5rem);
}
.slick-slider .slick-arrow .material-symbols-outlined {
  font-size: 3.4rem;
}
.slick-slider + .slider-paging {
  display: flex;
  font-size: 1.4rem;
  justify-content: center;
  line-height: normal;
}

.slider-pub {
  width: 100%;
}
.slider-pub:not(.slick-slider) {
  display: flex;
  overflow: hidden;
}
.slider-pub:not(.slick-slider) .slider-pub-item {
  flex: 0 0 54.4rem;
}
@media (max-width: 767.98px) {
  .slider-pub:not(.slick-slider) .slider-pub-item {
    flex: 0 0 100%;
  }
}
.slider-pub .slick-list {
  padding: 0 !important;
}
.slider-pub .slick-track {
  display: flex;
}
@media (min-width: 1200px) {
  .slider-pub-wrapper .slick-slider .slick-list {
    padding-right: 0 !important;
  }
}
.slider-pub-wrapper .slick-active {
  opacity: 1 !important;
}
.slider-pub-item {
  display: flex !important;
  padding: 1rem 0;
  max-width: 54.4rem;
  width: 54.4rem;
  margin-right: 1.6rem;
}
@media (max-width: 767.98px) {
  .slider-pub-item {
    flex-direction: column;
    width: 100%;
  }
}
.slider-pub-item a {
  text-decoration: none;
}
.slider-pub-image {
  flex-shrink: 0;
  background: #ebebeb;
  width: 21rem;
}
@media (max-width: 767.98px) {
  .slider-pub-image {
    margin: 0 auto 1.6rem;
  }
}
.slider-pub-image img {
  width: 100%;
}
.slider-pub-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.slider-pub-content .incaicon {
  font-size: 2.4rem;
  margin-left: 0.5rem;
  vertical-align: middle;
}
.slider-pub-content .slider-pub-title .like-h4 {
  font-size: 2.4rem;
  line-height: 1.25;
}
@media (min-width: 768px) {
  .slider-pub-content .slider-pub-title .like-h4 {
    font-size: 1.8rem;
    line-height: 1.33;
  }
}

@keyframes plyr-progress {
  to {
    background-position: var(--plyr-progress-loading-size, 25px) 0;
  }
}
@keyframes plyr-popup {
  0% {
    opacity: 0.5;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes plyr-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.plyr {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  direction: ltr;
  display: flex;
  flex-direction: column;
  font-family: var(--plyr-font-family, inherit);
  font-variant-numeric: tabular-nums;
  font-weight: var(--plyr-font-weight-regular, 400);
  line-height: var(--plyr-line-height, 1.7);
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  transition: box-shadow 0.3s ease;
  z-index: 0;
}
.plyr video,
.plyr audio,
.plyr iframe {
  display: block;
  height: 100%;
  width: 100%;
}
.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto;
}
.plyr:focus {
  outline: 0;
}

.plyr--full-ui {
  box-sizing: border-box;
}
.plyr--full-ui *,
.plyr--full-ui *::after,
.plyr--full-ui *::before {
  box-sizing: inherit;
}

.plyr--full-ui a,
.plyr--full-ui button,
.plyr--full-ui input,
.plyr--full-ui label {
  touch-action: manipulation;
}

.plyr__badge {
  background: var(--plyr-badge-background, hsl(216, 15%, 34%));
  border-radius: var(--plyr-badge-border-radius, 2px);
  color: var(--plyr-badge-text-color, #fff);
  font-size: var(--plyr-font-size-badge, 9px);
  line-height: 1;
  padding: 3px 4px;
}

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none;
}

.plyr__captions {
  animation: plyr-fade-in 0.3s ease;
  bottom: 0;
  display: none;
  font-size: var(--plyr-font-size-small, 13px);
  left: 0;
  padding: var(--plyr-control-spacing, 10px);
  position: absolute;
  text-align: center;
  transition: transform 0.4s ease-in-out;
  width: 100%;
}
.plyr__captions span:empty {
  display: none;
}
@media (min-width: 480px) {
  .plyr__captions {
    font-size: var(--plyr-font-size-base, 15px);
    padding: calc(var(--plyr-control-spacing, 10px) * 2);
  }
}
@media (min-width: 768px) {
  .plyr__captions {
    font-size: var(--plyr-font-size-large, 18px);
  }
}

.plyr--captions-active .plyr__captions {
  display: block;
}

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  transform: translateY(calc(var(--plyr-control-spacing, 10px) * -4));
}

.plyr__caption {
  background: var(--plyr-captions-background, rgba(0, 0, 0, 0.8));
  border-radius: 2px;
  box-decoration-break: clone;
  color: var(--plyr-captions-text-color, #fff);
  line-height: 185%;
  padding: 0.2em 0.5em;
  white-space: pre-wrap;
}
.plyr__caption div {
  display: inline;
}

.plyr__control {
  background: transparent;
  border: 0;
  border-radius: var(--plyr-control-radius, 4px);
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: calc(var(--plyr-control-spacing, 10px) * 0.7);
  position: relative;
  transition: all 0.3s ease;
}
.plyr__control svg {
  display: block;
  fill: currentColor;
  height: var(--plyr-control-icon-size, 18px);
  pointer-events: none;
  width: var(--plyr-control-icon-size, 18px);
}
.plyr__control:focus {
  outline: 0;
}
.plyr__control:focus-visible {
  outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, hsl(198, 100%, 50%))));
  outline-offset: 2px;
}

a.plyr__control {
  text-decoration: none;
}
a.plyr__control::after, a.plyr__control::before {
  display: none;
}

.plyr__control:not(.plyr__control--pressed) .icon--pressed,
.plyr__control.plyr__control--pressed .icon--not-pressed,
.plyr__control:not(.plyr__control--pressed) .label--pressed,
.plyr__control.plyr__control--pressed .label--not-pressed {
  display: none;
}

.plyr--full-ui ::-webkit-media-controls {
  display: none;
}

.plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center;
}
.plyr__controls .plyr__progress__container {
  flex: 1;
  min-width: 0;
}
.plyr__controls .plyr__controls__item {
  margin-left: calc(var(--plyr-control-spacing, 10px) / 4);
}
.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto;
}
.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: calc(var(--plyr-control-spacing, 10px) / 4);
}
.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 calc(var(--plyr-control-spacing, 10px) / 2);
}
.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time {
  padding-left: 0;
}
.plyr__controls:empty {
  display: none;
}

.plyr [data-plyr=captions],
.plyr [data-plyr=pip],
.plyr [data-plyr=airplay],
.plyr [data-plyr=fullscreen] {
  display: none;
}

.plyr--captions-enabled [data-plyr=captions],
.plyr--pip-supported [data-plyr=pip],
.plyr--airplay-supported [data-plyr=airplay],
.plyr--fullscreen-enabled [data-plyr=fullscreen] {
  display: inline-block;
}

.plyr__menu {
  display: flex;
  position: relative;
}
.plyr__menu .plyr__control svg {
  transition: transform 0.3s ease;
}
.plyr__menu .plyr__control[aria-expanded=true] svg {
  transform: rotate(90deg);
}
.plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none;
}
.plyr__menu__container {
  animation: plyr-popup 0.2s ease;
  background: var(--plyr-menu-background, rgba(255, 255, 255, 0.9));
  border-radius: var(--plyr-menu-radius, 8px);
  bottom: 100%;
  box-shadow: var(--plyr-menu-shadow, 0 1px 2px rgba(0, 0, 0, 0.15));
  color: var(--plyr-menu-color, hsl(216, 15%, 34%));
  font-size: var(--plyr-font-size-base, 15px);
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3;
}
.plyr__menu__container > div {
  overflow: hidden;
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}
.plyr__menu__container::after {
  border: var(--plyr-menu-arrow-size, 4px) solid transparent;
  border-top-color: var(--plyr-menu-background, rgba(255, 255, 255, 0.9));
  content: "";
  height: 0;
  position: absolute;
  right: calc(var(--plyr-control-icon-size, 18px) / 2 + calc(var(--plyr-control-spacing, 10px) * 0.7) - var(--plyr-menu-arrow-size, 4px) / 2);
  top: 100%;
  width: 0;
}
.plyr__menu__container [role=menu] {
  padding: calc(var(--plyr-control-spacing, 10px) * 0.7);
}
.plyr__menu__container [role=menuitem],
.plyr__menu__container [role=menuitemradio] {
  margin-top: 2px;
}
.plyr__menu__container [role=menuitem]:first-child,
.plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0;
}
.plyr__menu__container .plyr__control {
  align-items: center;
  color: var(--plyr-menu-color, hsl(216, 15%, 34%));
  display: flex;
  font-size: var(--plyr-font-size-menu, var(--plyr-font-size-small, 13px));
  padding: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) / 1.5) calc(calc(var(--plyr-control-spacing, 10px) * 0.7) * 1.5);
  user-select: none;
  width: 100%;
}
.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%;
}
.plyr__menu__container .plyr__control::after {
  border: var(--plyr-menu-item-arrow-size, 4px) solid transparent;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.plyr__menu__container .plyr__control--forward {
  padding-right: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) * 4);
}
.plyr__menu__container .plyr__control--forward::after {
  border-left-color: var(--plyr-menu-arrow-color, hsl(216, 15%, 52%));
  right: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) * 1.5 - var(--plyr-menu-item-arrow-size, 4px));
}
.plyr__menu__container .plyr__control--forward:focus-visible::after, .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor;
}
.plyr__menu__container .plyr__control--back {
  font-weight: var(--plyr-font-weight-regular, 400);
  margin: calc(var(--plyr-control-spacing, 10px) * 0.7);
  margin-bottom: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) / 2);
  padding-left: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) * 4);
  position: relative;
  width: calc(100% - calc(var(--plyr-control-spacing, 10px) * 0.7) * 2);
}
.plyr__menu__container .plyr__control--back::after {
  border-right-color: var(--plyr-menu-arrow-color, hsl(216, 15%, 52%));
  left: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) * 1.5 - var(--plyr-menu-item-arrow-size, 4px));
}
.plyr__menu__container .plyr__control--back::before {
  background: var(--plyr-menu-back-border-color, hsl(216, 15%, 88%));
  box-shadow: 0 1px 0 var(--plyr-menu-back-border-shadow-color, #fff);
  content: "";
  height: 1px;
  left: 0;
  margin-top: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) / 2);
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%;
}
.plyr__menu__container .plyr__control--back:focus-visible::after, .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor;
}
.plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: calc(var(--plyr-control-spacing, 10px) * 0.7);
}
.plyr__menu__container .plyr__control[role=menuitemradio]::before, .plyr__menu__container .plyr__control[role=menuitemradio]::after {
  border-radius: 100%;
}
.plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: "";
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: var(--plyr-control-spacing, 10px);
  transition: all 0.3s ease;
  width: 16px;
}
.plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%) scale(0);
  transition: transform 0.3s ease, opacity 0.3s ease;
  width: 6px;
}
.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: var(--plyr-control-toggle-checked-background, var(--plyr-color-main, var(--plyr-color-main, hsl(198, 100%, 50%))));
}
.plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  transform: translateY(-50%) scale(1);
}
.plyr__menu__container .plyr__control[role=menuitemradio]:focus-visible::before, .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(35, 40, 47, 0.1);
}
.plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: calc((calc(var(--plyr-control-spacing, 10px) * 0.7) - 2px) * -1);
  overflow: hidden;
  padding-left: calc(calc(var(--plyr-control-spacing, 10px) * 0.7) * 3.5);
  pointer-events: none;
}

.plyr--full-ui input[type=range] {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: calc(var(--plyr-range-thumb-height, 13px) * 2);
  color: var(--plyr-range-fill-background, var(--plyr-color-main, var(--plyr-color-main, hsl(198, 100%, 50%))));
  display: block;
  height: calc(var(--plyr-range-thumb-active-shadow-width, 3px) * 2 + var(--plyr-range-thumb-height, 13px));
  margin: 0;
  min-width: 0;
  padding: 0;
  transition: box-shadow 0.3s ease;
  width: 100%;
}
.plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: transparent;
  border: 0;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  transition: box-shadow 0.3s ease;
  user-select: none;
  background-image: linear-gradient(to right, currentColor var(--value, 0%), transparent var(--value, 0%));
}
.plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: var(--plyr-range-thumb-background, #fff);
  border: 0;
  border-radius: 100%;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2));
  height: var(--plyr-range-thumb-height, 13px);
  position: relative;
  transition: all 0.2s ease;
  width: var(--plyr-range-thumb-height, 13px);
  appearance: none;
  margin-top: calc((var(--plyr-range-thumb-height, 13px) - var(--plyr-range-track-height, 5px)) / 2 * -1);
}
.plyr--full-ui input[type=range]::-moz-range-track {
  background: transparent;
  border: 0;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  transition: box-shadow 0.3s ease;
  user-select: none;
}
.plyr--full-ui input[type=range]::-moz-range-thumb {
  background: var(--plyr-range-thumb-background, #fff);
  border: 0;
  border-radius: 100%;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2));
  height: var(--plyr-range-thumb-height, 13px);
  position: relative;
  transition: all 0.2s ease;
  width: var(--plyr-range-thumb-height, 13px);
}
.plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
}
.plyr--full-ui input[type=range]::-ms-track {
  background: transparent;
  border: 0;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  transition: box-shadow 0.3s ease;
  user-select: none;
  color: transparent;
}
.plyr--full-ui input[type=range]::-ms-fill-upper {
  background: transparent;
  border: 0;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  transition: box-shadow 0.3s ease;
  user-select: none;
}
.plyr--full-ui input[type=range]::-ms-fill-lower {
  background: transparent;
  border: 0;
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  transition: box-shadow 0.3s ease;
  user-select: none;
  background: currentColor;
}
.plyr--full-ui input[type=range]::-ms-thumb {
  background: var(--plyr-range-thumb-background, #fff);
  border: 0;
  border-radius: 100%;
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2));
  height: var(--plyr-range-thumb-height, 13px);
  position: relative;
  transition: all 0.2s ease;
  width: var(--plyr-range-thumb-height, 13px);
  margin-top: 0;
}
.plyr--full-ui input[type=range]::-ms-tooltip {
  display: none;
}
.plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0;
}
.plyr--full-ui input[type=range]:focus {
  outline: 0;
}
.plyr--full-ui input[type=range]:focus-visible::-webkit-slider-runnable-track {
  outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, hsl(198, 100%, 50%))));
  outline-offset: 2px;
}
.plyr--full-ui input[type=range]:focus-visible::-moz-range-track {
  outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, hsl(198, 100%, 50%))));
  outline-offset: 2px;
}
.plyr--full-ui input[type=range]:focus-visible::-ms-track {
  outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, hsl(198, 100%, 50%))));
  outline-offset: 2px;
}

.plyr__poster {
  background-color: var(--plyr-video-background, var(--plyr-video-background, rgb(0, 0, 0)));
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease;
  width: 100%;
  z-index: 1;
}

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1;
}

.plyr--youtube.plyr--paused.plyr__poster-enabled:not(.plyr--stopped) .plyr__poster {
  display: none;
}

.plyr__time {
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
}

.plyr__time + .plyr__time::before {
  content: "⁄";
  margin-right: var(--plyr-control-spacing, 10px);
}
@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none;
  }
}

/* stylelint-disable selector-max-compound-selectors */
.plyr__tooltip {
  background: var(--plyr-tooltip-background, #fff);
  border-radius: var(--plyr-tooltip-radius, 5px);
  bottom: 100%;
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px rgba(0, 0, 0, 0.15));
  color: var(--plyr-tooltip-color, hsl(216, 15%, 34%));
  font-size: var(--plyr-font-size-small, 13px);
  font-weight: var(--plyr-font-weight-regular, 400);
  left: 50%;
  line-height: 1.3;
  margin-bottom: calc(calc(var(--plyr-control-spacing, 10px) / 2) * 2);
  opacity: 0;
  padding: calc(var(--plyr-control-spacing, 10px) / 2) calc(calc(var(--plyr-control-spacing, 10px) / 2) * 1.5);
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
  white-space: nowrap;
  z-index: 2;
}
.plyr__tooltip::before {
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, #fff);
  bottom: calc(var(--plyr-tooltip-arrow-size, 4px) * -1);
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2;
}

.plyr .plyr__control:hover .plyr__tooltip,
.plyr .plyr__control:focus-visible .plyr__tooltip,
.plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 0 100%;
}
.plyr__controls > .plyr__control:first-child .plyr__tooltip::before,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: calc(var(--plyr-control-icon-size, 18px) / 2 + calc(var(--plyr-control-spacing, 10px) * 0.7));
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 100% 100%;
}
.plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: calc(var(--plyr-control-icon-size, 18px) / 2 + calc(var(--plyr-control-spacing, 10px) * 0.7));
  transform: translateX(50%);
}

.plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child:focus-visible .plyr__tooltip,
.plyr__controls > .plyr__control:first-child .plyr__tooltip--visible,
.plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control:focus-visible .plyr__tooltip,
.plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible,
.plyr__controls > .plyr__control:last-child:hover .plyr__tooltip,
.plyr__controls > .plyr__control:last-child:focus-visible .plyr__tooltip,
.plyr__controls > .plyr__control:last-child .plyr__tooltip--visible {
  transform: translate(0, 0) scale(1);
}

.plyr__progress {
  left: calc(var(--plyr-range-thumb-height, 13px) * 0.5);
  margin-right: var(--plyr-range-thumb-height, 13px);
  position: relative;
}
.plyr__progress input[type=range], .plyr__progress__buffer {
  margin-left: calc(var(--plyr-range-thumb-height, 13px) * -0.5);
  margin-right: calc(var(--plyr-range-thumb-height, 13px) * -0.5);
  width: calc(100% + var(--plyr-range-thumb-height, 13px));
}
.plyr__progress input[type=range] {
  position: relative;
  z-index: 2;
}
.plyr__progress .plyr__tooltip {
  left: 0;
  max-width: 120px;
  overflow-wrap: break-word;
}

.plyr__progress__buffer {
  -webkit-appearance: none; /* stylelint-disable-line */
  background: transparent;
  border: 0;
  border-radius: 100px;
  height: var(--plyr-range-track-height, 5px);
  left: 0;
  margin-top: calc(var(--plyr-range-track-height, 5px) / 2 * -1);
  padding: 0;
  position: absolute;
  top: 50%;
}
.plyr__progress__buffer::-webkit-progress-bar {
  background: transparent;
}
.plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: var(--plyr-range-track-height, 5px);
  transition: width 0.2s ease;
}
.plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: var(--plyr-range-track-height, 5px);
  transition: width 0.2s ease;
}
.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  transition: width 0.2s ease;
}

.plyr--loading .plyr__progress__buffer {
  animation: plyr-progress 1s linear infinite;
  background-image: linear-gradient(-45deg, var(--plyr-progress-loading-background, rgba(35, 40, 47, 0.6)) 25%, transparent 25%, transparent 50%, var(--plyr-progress-loading-background, rgba(35, 40, 47, 0.6)) 50%, var(--plyr-progress-loading-background, rgba(35, 40, 47, 0.6)) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: var(--plyr-progress-loading-size, 25px) var(--plyr-progress-loading-size, 25px);
  color: transparent;
}

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25));
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6));
}

.plyr__progress__marker {
  background-color: var(--plyr-progress-marker-background, #fff);
  border-radius: 1px;
  height: var(--plyr-range-track-height, 5px);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: var(--plyr-progress-marker-width, 3px);
  z-index: 3;
}

.plyr__volume {
  align-items: center;
  display: flex;
  position: relative;
}
.plyr__volume input[type=range] {
  margin-left: calc(var(--plyr-control-spacing, 10px) / 2);
  margin-right: calc(var(--plyr-control-spacing, 10px) / 2);
  max-width: 90px;
  min-width: 60px;
  position: relative;
  z-index: 2;
}

.plyr--audio {
  display: block;
}

.plyr--audio .plyr__controls {
  background: var(--plyr-audio-controls-background, #fff);
  border-radius: inherit;
  color: var(--plyr-audio-control-color, hsl(216, 15%, 34%));
  padding: var(--plyr-control-spacing, 10px);
}

.plyr--audio .plyr__control:focus-visible, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: var(--plyr-audio-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, hsl(198, 100%, 50%))));
  color: var(--plyr-audio-control-color-hover, #fff);
}

.plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6)));
}
.plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6)));
}
.plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6)));
}
.plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(35, 40, 47, 0.1));
}
.plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(35, 40, 47, 0.1));
}
.plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(35, 40, 47, 0.1));
}

.plyr--audio .plyr__progress__buffer {
  color: var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6));
}

.plyr--video {
  overflow: hidden;
}
.plyr--video.plyr--menu-open {
  overflow: visible;
}

.plyr__video-wrapper {
  background: var(--plyr-video-background, var(--plyr-video-background, rgb(0, 0, 0)));
  border-radius: inherit;
  height: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.plyr__video-embed,
.plyr__video-wrapper--fixed-ratio {
  aspect-ratio: 16/9;
}
@supports not (aspect-ratio: 16/9) {
  .plyr__video-embed,
  .plyr__video-wrapper--fixed-ratio {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
  }
}

.plyr__video-embed iframe,
.plyr__video-wrapper--fixed-ratio video {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.28125%);
}

.plyr--video .plyr__controls {
  background: var(--plyr-video-controls-background, linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75)));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: var(--plyr-video-control-color, #fff);
  left: 0;
  padding: calc(var(--plyr-control-spacing, 10px) / 2);
  padding-top: calc(var(--plyr-control-spacing, 10px) * 2);
  position: absolute;
  right: 0;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  z-index: 3;
}
@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: var(--plyr-control-spacing, 10px);
    padding-top: calc(var(--plyr-control-spacing, 10px) * 3.5);
  }
}

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
}

.plyr--video .plyr__control:focus-visible, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded=true] {
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, hsl(198, 100%, 50%))));
  color: var(--plyr-video-control-color-hover, #fff);
}

.plyr__control--overlaid {
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, hsl(198, 100%, 50%))));
  border: 0;
  border-radius: 100%;
  color: var(--plyr-video-control-color, #fff);
  display: none;
  left: 50%;
  opacity: 0.9;
  padding: calc(var(--plyr-control-spacing, 10px) * 1.5);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
  z-index: 2;
}
.plyr__control--overlaid svg {
  left: 2px;
  position: relative;
}
.plyr__control--overlaid:hover, .plyr__control--overlaid:focus {
  opacity: 1;
}

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block;
}

.plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25)));
}
.plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25)));
}
.plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25)));
}
.plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(255, 255, 255, 0.5));
}
.plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(255, 255, 255, 0.5));
}
.plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(255, 255, 255, 0.5));
}

.plyr--video .plyr__progress__buffer {
  color: var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25));
}

.plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}
.plyr:fullscreen video {
  height: 100%;
}
.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}
.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}
.plyr:fullscreen.plyr--hide-controls {
  cursor: none;
}
@media (min-width: 1024px) {
  .plyr:fullscreen .plyr__captions {
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000;
}
.plyr--fullscreen-fallback video {
  height: 100%;
}
.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block;
}
.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}
.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none;
}
@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.plyr__ads > div,
.plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%;
}
.plyr__ads::after {
  background: hsl(216, 15%, 16%);
  border-radius: 2px;
  bottom: var(--plyr-control-spacing, 10px);
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: var(--plyr-control-spacing, 10px);
  z-index: 3;
}
.plyr__ads:empty::after {
  display: none;
}

.plyr__cues {
  background: currentColor;
  display: block;
  height: var(--plyr-range-track-height, 5px);
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  z-index: 3;
}

.plyr__preview-thumb {
  background-color: var(--plyr-tooltip-background, #fff);
  border-radius: var(--plyr-menu-radius, 8px);
  bottom: 100%;
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px rgba(0, 0, 0, 0.15));
  margin-bottom: calc(calc(var(--plyr-control-spacing, 10px) / 2) * 2);
  opacity: 0;
  padding: 3px;
  pointer-events: none;
  position: absolute;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
  z-index: 2;
}
.plyr__preview-thumb--is-shown {
  opacity: 1;
  transform: translate(0, 0) scale(1);
}
.plyr__preview-thumb::before {
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, #fff);
  bottom: calc(var(--plyr-tooltip-arrow-size, 4px) * -1);
  content: "";
  height: 0;
  left: calc(50% + var(--preview-arrow-offset));
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2;
}
.plyr__preview-thumb__image-container {
  background: hsl(216, 15%, 79%);
  border-radius: calc(var(--plyr-menu-radius, 8px) - 1px);
  overflow: hidden;
  position: relative;
  z-index: 0;
}
.plyr__preview-thumb__image-container img, .plyr__preview-thumb__image-container::after {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.plyr__preview-thumb__image-container::after {
  border-radius: inherit;
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.15);
  content: "";
  pointer-events: none;
}
.plyr__preview-thumb__image-container img {
  max-height: none;
  max-width: none;
}
.plyr__preview-thumb__time-container {
  background: var(--plyr-video-controls-background, linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75)));
  border-bottom-left-radius: calc(var(--plyr-menu-radius, 8px) - 1px);
  border-bottom-right-radius: calc(var(--plyr-menu-radius, 8px) - 1px);
  bottom: 0;
  left: 0;
  line-height: 1.1;
  padding: 20px 6px 6px;
  position: absolute;
  right: 0;
  z-index: 3;
}
.plyr__preview-thumb__time-container span {
  color: #fff;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
}

.plyr__preview-scrubbing {
  bottom: 0;
  filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;
  width: 100%;
  z-index: 1;
}
.plyr__preview-scrubbing--is-shown {
  opacity: 1;
}
.plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%;
}

.plyr--no-transition {
  transition: none !important;
}

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.plyr [hidden] {
  display: none !important;
}

.plyr {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  direction: ltr;
  display: flex;
  flex-direction: column;
  font-family: var(--plyr-font-family, inherit);
  font-variant-numeric: tabular-nums;
  font-weight: var(--plyr-font-weight-regular, 400);
  line-height: var(--plyr-line-height, 1.7);
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  transition: box-shadow 0.3s ease;
  z-index: 0;
}
.plyr video,
.plyr audio,
.plyr iframe {
  display: block;
  height: 100%;
  width: 100%;
}
.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto;
}
.plyr:focus {
  outline: 0;
}

.plyr--full-ui {
  box-sizing: border-box;
}
.plyr--full-ui *,
.plyr--full-ui *::after,
.plyr--full-ui *::before {
  box-sizing: inherit;
}

.plyr--full-ui a,
.plyr--full-ui button,
.plyr--full-ui input,
.plyr--full-ui label {
  touch-action: manipulation;
}

.video {
  --plyr-color-main: var(--bs-primary);
  margin: 4rem 0;
}
.video__content {
  max-width: 88.8rem;
  position: relative;
}
.video__img {
  position: relative;
  height: 100%;
}
.video__img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--bs-zodiac);
  opacity: 0.34;
}
.video__img picture {
  --bs-aspect-ratio: 56.25%;
  position: relative;
  width: 100%;
  height: 100%;
}
.video__img picture::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.video__img img {
  height: 100%;
  width: auto;
  max-width: none;
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}
.video .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
}
.video .plyr {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.video .plyr__control--overlaid {
  --plyr-control-icon-size: 2.2rem;
  background-color: transparent;
  border: 0.2rem solid var(--bs-white);
  width: 8rem;
  height: 8rem;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.video .plyr iframe {
  width: 100%;
  height: 100%;
  display: block;
}
.video .plyr ~ .btn {
  display: none;
}

.media-full-block {
  width: 100%;
}
.media-full-block-inner {
  position: relative;
}
.media-full-block-media {
  height: 56rem;
}
@media screen and (min-width: 1920px) {
  .media-full-block-media {
    height: 71rem;
  }
}
@media (max-width: 767.98px) {
  .media-full-block-media {
    height: 46.4rem;
    position: relative;
  }
}
@media (max-width: 767.98px) {
  .media-full-block-media::before {
    background-image: linear-gradient(0deg, rgba(var(--ecume-125), 0.8) 34.91%, rgba(var(--bs-gray-850), 0.12) 70.26%);
    bottom: 0;
    border-radius: 2.4rem 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}
.media-full-block-media picture {
  height: 100%;
}
.media-full-block-media img {
  aspect-ratio: 2.7;
  border-radius: 2rem 0.2rem 2rem 0;
  height: 100%;
  object-fit: cover;
  width: 100%;
}
@media (max-width: 767.98px) {
  .media-full-block-media img {
    aspect-ratio: 0.769;
    border-radius: 2.4rem 0;
  }
}
.hp-borderless ~ main .media-full-block-media img {
  border-radius: 0 0 2rem;
}
@media (max-width: 767.98px) {
  .hp-borderless ~ main .media-full-block-media img {
    border-radius: 0 0 2.4rem;
  }
}
.media-full-block-media .video {
  border-radius: 2rem 0.2rem 2rem 0;
  margin: 0;
  overflow: hidden;
}
.media-full-block-media .video__content {
  max-width: none;
}
.media-full-block-media .video .btn {
  left: auto;
  right: 3.2rem;
  top: 3.2rem;
  transform: none;
}
.media-full-block-media .video .btn .material-symbols-outlined {
  font-size: 1.8rem;
}
.media-full-block-info {
  background: var(--bs-white);
  display: flex;
  flex-direction: column;
  padding: 3rem 4rem 4rem;
  position: relative;
  width: 100%;
  z-index: 1;
}
@media (min-width: 768px) {
  .media-full-block-info {
    border-top: 1rem solid var(--ecume-360);
    margin: -14rem 0 0;
  }
}
@media (max-width: 767.98px) {
  .media-full-block-info {
    background: transparent;
    bottom: 0;
    color: var(--bs-white);
    left: 0;
    padding: 0 1.6rem 2.4rem;
    position: absolute;
    z-index: 2;
  }
}
.media-full-block-info .border-bg {
  background: url("../../../images/media-full-block-border.svg") no-repeat 0 0;
  display: block;
  height: 64.8rem;
  left: -24.8rem;
  position: absolute;
  top: 5.4rem;
  width: 22.8rem;
}
@media (max-width: 1399.98px) {
  .media-full-block-info .border-bg {
    display: none;
  }
}
.media-full-block-title {
  line-height: 1.125;
  order: 2;
}
.media-full-block-surtitle {
  font-size: 2.4rem;
  font-weight: 800;
  line-height: 1.25;
  order: 1;
}
@media (max-width: 767.98px) {
  .media-full-block-surtitle {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.75;
  }
}
.media-full-block-text {
  order: 3;
}

.multiple-articles.block-bg-dark > .card-body * {
  color: var(--ecume-125);
}
.multiple-articles.block-bg-dark .col-aside * {
  color: var(--ecume-125);
}
.multiple-articles .row {
  --bs-gutter-x: 1.6rem;
}
@media (max-width: 767.98px) {
  .multiple-articles .row {
    gap: 1.6rem;
  }
}
.multiple-articles .row > [class*=col-]:first-child .card {
  height: 51.6rem;
}
@media (max-width: 991.98px) {
  .multiple-articles .row > [class*=col-]:first-child .card {
    height: 100%;
  }
}
@media (max-width: 767.98px) {
  .multiple-articles .row > [class*=col-]:first-child .card {
    --bs-card-border-width: .2rem;
    min-height: 22rem;
  }
}
@media (max-width: 767.98px) {
  .multiple-articles .col-aside .card-body {
    padding: 2.2rem 1.4rem 3rem;
  }
}
@media (max-width: 767.98px) {
  .multiple-articles .col-aside .card-body .material-symbols-outlined:last-child {
    bottom: 3.2rem;
  }
}
.multiple-articles .col-aside > ul {
  display: grid;
  gap: 2.4rem;
  margin: 0;
}
@media (max-width: 767.98px) {
  .multiple-articles .col-aside > ul {
    gap: 1.6rem;
  }
}

.push-wrapper .row {
  --bs-gutter-x: 3.2rem;
  gap: 3.2rem 0;
}
.push-wrapper .row > [class$="-6"] .landing-page__block, .push-wrapper .row > [class$="-6"] .push, .push-wrapper .row > [class$="-6"] .card, .push-wrapper .row > [class$="-6"] .container {
  height: 100%;
}
.push-wrapper .row > [class$="-6"] .card {
  flex-direction: column;
}
@media (min-width: 768px) {
  .push-wrapper .row > [class$="-6"] .card-title {
    font-size: 1.8rem;
  }
}
.push.block-bg-dark .card-inca-horizontal {
  --bs-card-bg: var(--ecume-125);
  --bs-border-radius: 0;
  border: 0;
  padding: 2.4rem 0;
}
@media (max-width: 767.98px) {
  .push.block-bg-dark .card-inca-horizontal {
    padding: 6rem 0;
  }
}
.push.block-bg-dark .card-inca-horizontal * {
  color: var(--bs-white);
}
.push.block-bg-dark .card-inca-horizontal .card-body {
  padding: 0 2.4rem 7.2rem;
}
@media (max-width: 767.98px) {
  .push.block-bg-dark .card-inca-horizontal .card-body {
    padding: 2.4rem 1.6rem 7.2rem;
  }
}
.push.block-bg-dark .card-inca-horizontal .card-body .material-symbols-outlined {
  bottom: 4.8rem;
}
@media (max-width: 767.98px) {
  .push.block-bg-dark .card-inca-horizontal .card-body .material-symbols-outlined {
    bottom: 8.4rem;
    right: 1.6rem;
  }
}
.push.block-bg-dark .card-inca-horizontal .card-body .btn {
  --bs-btn-border-color: var(--bs-white);
  color: var(--bs-white);
}
.push.block-bg-dark .card-inca-horizontal .card-body .btn:hover, .push.block-bg-dark .card-inca-horizontal .card-body .btn:focus {
  --bs-btn-hover-border-color: var(--bs-white);
  --bs-btn-hover-bg: var(--ecume-200);
  --bs-btn-hover-color: var(--bs-white);
}
.push.block-bg-dark .card-inca-horizontal .card-title {
  color: var(--bs-white);
  font-size: 3.2rem;
}
@media (max-width: 767.98px) {
  .push.block-bg-dark .card-inca-horizontal .card-title {
    font-size: 2.4rem;
    margin: 0 0 2.4rem;
  }
}
.push .card-inca .card-body-w-btn {
  align-self: flex-start;
  padding: 3.8rem 2.4rem 4.6rem 3rem;
  max-width: calc(100% - 14.5rem);
}
@media (max-width: 767.98px) {
  .push .card-inca .card-body-w-btn {
    max-width: 100%;
  }
}
@media (max-width: 767.98px) {
  .push .card-inca .card-body-w-btn {
    padding: 2.2rem 2.2rem 3rem;
  }
}
@media (max-width: 767.98px) {
  .push .card-inca .card-body-w-btn .card-end {
    font-size: 1.6rem;
  }
}
.push .card-inca .card-body-w-btn .btn {
  align-self: center;
  margin: 2.4rem 0 0;
}
@media (min-width: 768px) {
  .push .card-inca .card-body-w-btn .btn {
    bottom: 4.6rem;
    margin: 0;
    position: absolute;
    right: 3rem;
  }
}

.w848 .push-wrapper {
  margin-top: 0;
}
.w848 .push-wrapper .container {
  padding-left: 0;
  padding-right: 0;
}
.w848 .push-wrapper .row {
  margin-left: -0.8rem;
  margin-right: -0.8rem;
}
@media (max-width: 767.98px) {
  .w848 .push-wrapper .row {
    margin-left: 0;
    margin-right: 0;
  }
}
.w848 .push-wrapper .row > * {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
}
@media (max-width: 767.98px) {
  .w848 .push-wrapper .row > * {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

.video-block {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 110.4rem;
  padding: 2.8rem 0;
}
@media (max-width: 767.98px) {
  .video-block {
    max-width: 76.8rem;
    position: relative;
  }
}
.video-block-wrapper {
  margin-top: 10.8rem;
}
@media (max-width: 767.98px) {
  .video-block-wrapper {
    margin-top: 3.6rem;
  }
}
.video-block-wrapper.block-bg-dark {
  margin-top: 0;
}
.video-block-inner {
  position: relative;
}
.video-block-media {
  overflow: hidden;
  border-radius: 0 0 2rem;
}
.video-block-media .plyr__control--overlaid {
  width: 8rem;
  height: 8rem;
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: var(--emeraude-850);
  border: 1px solid var(--ecume-125);
}
.video-block-media .plyr__control--overlaid::before {
  width: 4.667rem;
  height: 4.667rem;
  border: 0.5rem solid var(--ecume-125);
  border-radius: 50%;
  position: absolute;
  content: "";
}
.video-block-media .plyr__control--overlaid svg {
  fill: var(--ecume-125);
}
.video-block-info {
  max-width: 65.6rem;
}
.video-block-info .btn-link {
  text-decoration: none;
}
.text-white .video-block-info .btn-link {
  color: white;
}
.block-bg-dark .video-block-info .btn-link:hover, .block-bg-dark .video-block-info .btn-link:focus-visible {
  color: var(--bs-white);
}
.block-bg-dark .video-block-info .btn-link:focus-visible {
  outline: 0.2rem solid var(--bs-white);
  outline-offset: 0.4rem;
}
.video-block-info .btn-link:hover .text {
  text-decoration: underline;
}
.video-block-info .btn-link span {
  vertical-align: middle;
}

.richtext {
  margin: 13.6rem 0 4.8rem;
}
@media (max-width: 767.98px) {
  .richtext {
    margin: 6.4rem 0 1.6rem;
  }
}
.richtext.block-bg-dark {
  margin: 0;
  padding: 13.6rem 0 4.8rem;
}
@media (max-width: 767.98px) {
  .richtext.block-bg-dark {
    padding: 6.4rem 0 1.6rem;
  }
}

.title-block {
  margin: 13.6rem 0 4.8rem;
}
@media (max-width: 767.98px) {
  .title-block {
    margin: 6.4rem 0 1.6rem;
  }
}
.title-block.block-bg-dark {
  margin: 0;
  padding-bottom: 4.8rem;
  padding-top: 13.6rem;
}
@media (max-width: 767.98px) {
  .title-block.block-bg-dark {
    padding-bottom: 1.6rem;
    padding-top: 6.4rem;
  }
}
.title-block.block-bg-dark .title-block-inner::after {
  background: var(--bs-white);
}
.title-block-section.block-bg-dark + div {
  margin-top: 0;
  padding-top: 0;
}
.title-block-section.block-bg-dark + div .block-bg-dark {
  padding-top: 0;
}
.title-block-section + div {
  margin-top: 0;
}
.title-block-section + div.landing-page__block > div:first-child {
  margin-top: 0;
}
.title-block-inner {
  gap: 1rem;
}
@media (max-width: 767.98px) {
  .title-block-inner {
    align-items: flex-end;
    gap: 0.4rem;
  }
}
.title-block-inner::after {
  background: var(--ecume-125);
  content: "";
  height: 0.2rem;
  flex-grow: 1;
  margin: 2.4rem 0 0;
}
@media (max-width: 767.98px) {
  .title-block-inner::after {
    margin: 0 0 2rem;
  }
}
.title-block-inner .title-block-item {
  line-height: normal;
  margin: 0 0 0.6rem;
  max-width: 62%;
}
@media (max-width: 767.98px) {
  .title-block-inner .title-block-item {
    line-height: 1.3333;
    max-width: 75%;
  }
}

.quote-block-inner {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 0;
}
@media (max-width: 991.98px) {
  .quote-block-inner {
    flex-direction: column;
  }
}
.quote-block-media {
  flex-shrink: 0;
  width: 50%;
}
@media (max-width: 991.98px) {
  .quote-block-media {
    width: 100%;
  }
}
.quote-block-media img {
  border-radius: 0.4rem 0.4rem 2.4rem;
  width: 100%;
  object-fit: cover;
}
@media (max-width: 991.98px) {
  .quote-block-media img {
    border-radius: 2.4rem 0.4rem;
  }
}
.quote-block-media .enhancedimage--wrapper {
  position: relative;
}
.quote-block-media .enhancedimage--wrapper::after {
  height: 100%;
  width: 0.2rem;
  content: "";
  position: absolute;
  right: 2.4rem;
  top: 0;
  background: var(--bs-white);
}
.quote-block-info {
  width: 50%;
  max-width: 67.2rem;
  position: relative;
  padding: 2.4rem 0 2.4rem 4.8rem;
}
@media (max-width: 991.98px) {
  .quote-block-info {
    max-width: 100%;
    width: 100%;
    padding-left: 4.3rem;
  }
}
.quote-block-info.no-image {
  padding-right: 2.4rem;
  padding-top: 3.2rem;
  max-width: 69.6rem;
}
.quote-block-info::before {
  background: var(--ecume-360);
  content: "";
  position: absolute;
  display: block;
}
@media (min-width: 992px) {
  .quote-block-info::before {
    height: 0.2rem;
    left: 0;
    right: 0;
  }
}
@media (max-width: 991.98px) {
  .quote-block-info::before {
    width: 0.2rem;
    left: 1.6rem;
    top: 0;
    bottom: 0;
  }
}
.quote-block-subtitle {
  font-size: 1.4rem;
  line-height: 1.3;
}
@media (max-width: 575.98px) {
  .quote-block-subtitle {
    font-size: 1.2rem;
  }
}
.quote-block-text {
  font-size: 3.2rem;
  line-height: 1.2;
  font-weight: 800;
  position: relative;
}
@media (max-width: 575.98px) {
  .quote-block-text {
    font-size: 2.4rem;
  }
}
.quote-block-text__inner {
  position: relative;
  z-index: 1;
}
.quote-block-text__decor {
  font-size: 18.3rem;
  line-height: 7.3rem;
  height: 7.3rem;
  position: absolute;
  top: -5.4rem;
  left: -6.8rem;
  color: var(--emeraude-850);
}
@media (min-width: 992px) {
  .no-image .quote-block-text__decor {
    top: -1.7rem;
  }
}
@media (max-width: 991.98px) {
  .quote-block-text__decor {
    left: -2.8rem;
  }
}
.block-bg-dark .quote-block-text__decor {
  color: var(--ecume-360);
}
.quote-block-text__decor::before {
  content: "«";
  position: absolute;
  top: 0.5rem;
}
.quote-block-link a {
  text-decoration: none;
}
.quote-block-link__text {
  font-weight: 800;
}
.quote-block-link .ibg {
  vertical-align: middle;
  margin-left: 0.8rem;
}
.block-bg-dark .quote-block-link .ibg {
  color: var(--ecume-125);
}

.block-bg-dark blockquote::before, blockquote.block-bg-dark::before {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOTAiIGhlaWdodD0iNzQiIHZpZXdCb3g9IjAgMCA5MCA3NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQ4Ljc4ODUgNzMuODI4NkwyNS41MDMxIDM3LjQ2OEw0OC43ODg1IDBIMjMuNjU1TDAgMzcuNjUyNkwyMy42NTUgNzMuODI4Nkg0OC43ODg1Wk05MCA3My44Mjg2TDY2LjcxNDYgMzcuNDY4TDkwIDBINjQuODY2NUw0MS4yMTE1IDM3LjY1MjZMNjQuODY2NSA3My44Mjg2SDkwWiIgZmlsbD0iIzNENTM5QiIvPgo8L3N2Zz4K") !important;
}

.services-block-inner {
  padding: 13.6rem 0;
}
@media (max-width: 991.98px) {
  .services-block-inner {
    padding: 6.4rem 0;
  }
}
.services-block-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.services-full .services-block-list {
  justify-content: flex-start;
}
.services-block-item {
  display: flex;
  align-items: center;
  margin-bottom: 3.2rem;
  width: 100%;
  position: relative;
}
@media (min-width: 992px) {
  .services-block-item {
    max-width: 50%;
  }
  .services-full .services-block-item {
    max-width: 33.333%;
  }
  .services-block-item:nth-last-child(-n+2) {
    margin-bottom: 0;
  }
}
.services-block-item__image {
  width: 8rem;
  flex-shrink: 0;
}
.services-block-item__text {
  margin-left: 1.6rem;
  font-size: 1.8rem;
  font-weight: 800;
}
.services-block-push {
  border-top: 1rem solid var(--ecume-360);
  padding: 3.8rem 3.2rem 0 2.4rem;
  background-color: var(--bs-white);
  border-radius: 0.2rem 0.2rem 2.4rem;
}
.services-block-push * {
  color: var(--ecume-125);
}
.services-block-push__text {
  line-height: 1.6;
}
.services-block-push__image {
  margin-left: 0.8rem;
}
.services-block-push__image img {
  width: 100%;
}

.overlapping-text.block {
  margin-bottom: 6.4rem;
}
@media (max-width: 767.98px) {
  .overlapping-text.block {
    margin-bottom: 3.2rem;
  }
}
.overlapping-text.block-bg-dark.block {
  margin: 0;
  padding-bottom: 6.4rem;
}
@media (max-width: 767.98px) {
  .overlapping-text.block-bg-dark.block {
    padding-bottom: 3.2rem;
  }
}
.overlapping-text.block-bg-dark .card-title {
  color: var(--ecume-125);
}
.overlapping-text.block-bg-dark .card-title a {
  color: inherit;
}
.overlapping-text.block-bg-dark .card-end, .overlapping-text.block-bg-dark .card-end *, .overlapping-text.block-bg-dark .card-start, .overlapping-text.block-bg-dark .card-start * {
  color: var(--ecume-125);
}
@media (max-width: 767.98px) {
  .overlapping-text .container {
    --bs-gutter-x: 0;
  }
}
@media (min-width: 768px) {
  .overlapping-text [class*=col-].order-1 {
    margin-right: -4.8rem;
  }
}
@media (max-width: 767.98px) {
  .overlapping-text [class*=col-].order-1 {
    margin-bottom: -4.8rem;
  }
}
.overlapping-text [class*=col-].order-2 {
  position: relative;
  z-index: 1;
}
.overlapping-text-img {
  position: relative;
}
.overlapping-text-img img {
  aspect-ratio: 1.2;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.4rem 0.4rem 2.4rem;
}
@media (max-width: 767.98px) {
  .overlapping-text-img img {
    border-radius: 2.4rem 0.4rem;
  }
}
@media (max-width: 767.98px) {
  .overlapping-text .card {
    margin: 0 1.2rem;
  }
}

.half-overlapped {
  max-width: 56.2rem;
}
.half-overlapped-wrapper {
  margin: 13.6rem 0 0;
}
@media (max-width: 767.98px) {
  .half-overlapped-wrapper {
    margin: 3.2rem 0;
  }
}
.half-overlapped-wrapper.block-bg-dark {
  padding: 13.6rem 0;
}
.half-overlapped-wrapper .container > .row {
  gap: 6.4rem 0;
}
@media (min-width: 1400px) {
  .half-overlapped-wrapper .container > .row {
    --bs-gutter-x: 11rem;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .half-overlapped-wrapper .container > .row {
    --bs-gutter-x: 11rem;
  }
}
.half-overlapped-wrapper .half-overlapped {
  margin: 0;
  padding: 0;
  max-width: 100%;
}
.half-overlapped [class*=col-].order-1 {
  max-width: 32rem;
}
@media (max-width: 1199.98px) {
  .half-overlapped [class*=col-].order-1 {
    margin-bottom: -13.6rem;
    max-width: 100%;
  }
}
@media (min-width: 1400px) {
  .half-overlapped [class*=col-].order-2 {
    width: calc(50% + 0.9rem);
  }
}
.half-overlapped .card-inca {
  background: rgba(var(--bs-white-rgb), 0.97);
  height: 40.8rem;
  overflow: hidden;
}
@media (max-width: 1199.98px) {
  .half-overlapped .card-inca {
    background: var(--bs-white);
    height: auto;
    margin: 0 1.6rem;
  }
}
.half-overlapped .card-inca .card-body {
  align-self: flex-start;
}
@media (max-width: 1199.98px) {
  .half-overlapped .card-inca .card-body .card-title {
    font-size: 1.8rem;
  }
}
@media (max-width: 1199.98px) {
  .half-overlapped .card-inca .card-body .card-end {
    font-size: 1.4rem;
  }
}
.half-overlapped .card-inca .card-body .material-symbols-outlined {
  position: absolute;
}
.half-overlapped img {
  aspect-ratio: 0.67;
  border-radius: 2.4rem 0.4rem;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

.list-block {
  margin: 6.4rem 0;
}
.list-block-title {
  font-size: 2.4rem;
}
@media (max-width: 767.98px) {
  .list-block-title {
    font-size: 1.8rem;
    line-height: 1.3333;
  }
}
.list-block-inner {
  align-items: center;
  border-bottom: 0.2rem solid var(--ecume-360);
  display: flex;
  gap: 0 0.8rem;
  justify-content: space-between;
  padding: 1.6rem 0;
}
@media (max-width: 767.98px) {
  .list-block-inner .btn {
    border-radius: 50%;
  }
}
.list-block-inner .btn .incaicon {
  font-size: 2.4rem;
}
@media (max-width: 767.98px) {
  .list-block-info {
    font-size: 1.4rem;
  }
}
.list-block-info .title {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.625;
  margin: 0;
}
@media (max-width: 767.98px) {
  .list-block-info .title {
    font-size: 1.4rem;
    line-height: 1.57;
  }
}
.list-block-info .doc-size {
  color: rgba(var(--ecume-125-rgb), 0.5);
  margin: 0;
}
.list-block-info .doc-size .format {
  text-transform: uppercase;
}
.list-block-info .ressource-link {
  font-weight: 800;
  text-decoration: none;
}
.list-block-info .ressource-link:hover, .list-block-info .ressource-link:focus {
  text-decoration: underline;
}
.list-block .list-block-info .ressource-link[target=_blank]:not(.btn)::after {
  content: "\e907";
  font-family: "icomoon";
  padding-left: 0.5rem;
  font-size: 80%;
}
.list-block-info .ressource-link .incaicon {
  margin-left: 0.4rem;
}
.list-block-info .last-num-link {
  text-decoration: underline;
}

.hp-profile-block {
  margin-top: -1.5rem;
}
.hp-profile-block-inner {
  align-items: center;
  position: relative;
}
@media (min-width: 768px) {
  .hp-profile-block-inner {
    padding-bottom: 0.4rem;
  }
}
@media (min-width: 768px) {
  .hp-profile-block-inner::after {
    left: 1.6rem;
    right: 1.6rem;
    height: 0.4rem;
    position: absolute;
    bottom: 0;
    background: var(--ecume-360);
    display: block;
    content: "";
  }
}
@media (max-width: 575.98px) {
  .hp-profile-block-media {
    margin: 0 -1.6rem;
  }
}
.hp-profile-block-media img {
  border-radius: 2.4rem 0.4rem 0 0;
  width: 100%;
  object-fit: cover;
}

.references-block {
  border-radius: 0.4rem 0.4rem 2.4rem;
}
.references-block h4 {
  font-weight: 800;
}
@media (max-width: 575.98px) {
  .references-block.block-bg-dark {
    margin-left: -1.6rem;
    margin-right: -1.6rem;
  }
}
.references-block.block-bg-dark ul li::before {
  background: var(--bs-white);
}
.references-block > *:last-child {
  margin-bottom: 0;
}
.wysiwyg .references-block .h3, .wysiwyg .references-block h3 {
  margin-top: 0;
}

.read-more {
  margin: 13.6rem 0;
}
@media (max-width: 767.98px) {
  .read-more {
    margin: 6.4rem 0;
  }
}
.read-more-list.slick-slider {
  display: flex;
}
@media (max-width: 767.98px) {
  .read-more-list.slick-slider {
    margin-left: 0;
    margin-right: 0;
  }
  .read-more-list.slick-slider .slick-list {
    padding: 0 !important;
  }
  .read-more-list.slick-slider .slick-slide {
    padding: 0 !important;
  }
}
@media (max-width: 767.98px) {
  .read-more-list:not(.slick-slider) {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }
}
@media (min-width: 768px) {
  .read-more-list .card-inca {
    height: 100%;
  }
}
.read-more-list .card-inca .card-body {
  align-self: flex-start;
}

.calendar-rebound {
  margin: 13.6rem 0;
}
@media (max-width: 767.98px) {
  .calendar-rebound {
    margin: 6.4rem 0;
  }
}
@media (min-width: 1200px) {
  .calendar-rebound-list {
    --bs-gutter-x: 4rem;
  }
}
.calendar-rebound-list.slick-slider {
  display: flex;
}
@media (max-width: 767.98px) {
  .calendar-rebound-list:not(.slick-slider) {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }
}
@media (min-width: 768px) {
  .calendar-rebound-list .card-inca {
    height: 100%;
  }
}
.calendar-rebound-list .card-inca .card-body {
  align-self: flex-start;
}
.calendar-rebound-list .card-inca .date, .calendar-rebound-list .card-inca .location {
  align-items: flex-start;
  display: flex;
  gap: 0 0.8rem;
}
.calendar-rebound-list .card-inca .date .incaicon, .calendar-rebound-list .card-inca .location .incaicon {
  font-size: 2rem;
  margin-top: 0.5rem;
}
@media (max-width: 767.98px) {
  .calendar-rebound-list .card-inca .date .incaicon, .calendar-rebound-list .card-inca .location .incaicon {
    margin-top: 0.3rem;
  }
}
.calendar-rebound-list .card-inca .date {
  margin-bottom: 0.8rem;
}
.calendar-rebound-list .card-inca .date + p {
  margin-top: 1.6rem;
}
.calendar-rebound-list .card-inca .location {
  margin-bottom: 1.6rem;
}

.visual-insert-wrapper .container > .row {
  gap: 6.4rem 0;
}
@media (min-width: 768px) {
  .visual-insert-wrapper .container > .row {
    --bs-gutter-x: 1.6rem;
  }
}
@media (max-width: 767.98px) {
  .visual-insert-wrapper .container > .row {
    gap: 2rem 0;
  }
}
.visual-insert-wrapper .container > .row.slick-slider {
  display: flex;
}
.visual-insert-wrapper .container > .row.slick-slider .slick-list {
  padding: 0 4rem 0 0 !important;
}
.visual-insert-wrapper .container > .row.slick-slider .slick-slide {
  padding: 0 0 0 1.6rem;
}
@media (max-width: 767.98px) {
  .visual-insert-wrapper .container > .row.slick-slider [class$="-6"] .card-inca {
    height: 43rem;
  }
}
@media (max-width: 767.98px) {
  .visual-insert-wrapper .container > .row.visual-insert-slider:not(.slick-slider) {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }
}
.visual-insert-wrapper .container > .row [class$="-6"] .card-inca {
  height: 54.6rem;
}
@media (max-width: 767.98px) {
  .visual-insert-wrapper .container > .row [class$="-6"] .card-inca {
    height: 27.3rem;
  }
}
.visual-insert-wrapper .container > .row [class$="-4"] .card-inca {
  height: 64.5rem;
}
@media (max-width: 767.98px) {
  .visual-insert-wrapper .container > .row [class$="-4"] .card-inca {
    height: 43rem;
  }
}
.visual-insert-wrapper .card-inca:hover .card-img-overlay, .visual-insert-wrapper .card-inca:focus .card-img-overlay, .visual-insert-wrapper .card-inca.has-focus .card-img-overlay {
  background: linear-gradient(0deg, rgba(34, 41, 64, 0.9) 59.01%, rgba(34, 41, 64, 0) 95.17%);
}
.visual-insert-wrapper .card-inca:hover .card-end, .visual-insert-wrapper .card-inca:focus .card-end, .visual-insert-wrapper .card-inca.has-focus .card-end {
  height: 100%;
  overflow: initial;
  transition: all 0.5s;
}
.visual-insert-wrapper .card-inca .card-img-overlay {
  background: linear-gradient(0deg, rgba(34, 41, 64, 0.8) 34.7%, rgba(34, 41, 64, 0) 68.22%);
}
@media (max-width: 767.98px) {
  .visual-insert-wrapper .card-inca .card-img-overlay {
    --bs-card-img-overlay-padding: 1.6rem 1.6rem 2.4rem 1.6rem;
  }
}
.visual-insert-wrapper .card-inca .card-title {
  margin-top: 0.8rem;
}
@media (min-width: 768px) {
  .visual-insert-wrapper .card-inca .card-title {
    font-size: 2.4rem;
  }
}
.visual-insert-wrapper .card-inca .card-start {
  line-height: 1.125;
}
.visual-insert-wrapper .card-inca .card-start p {
  margin: 0;
}
.visual-insert-wrapper .card-inca .card-end {
  height: 0;
  padding-top: 0.8rem;
  overflow: hidden;
  transition: all 0.5s;
}
@media (max-width: 767.98px) {
  .visual-insert-wrapper .card-inca .card-end {
    display: none;
  }
}

.fr-content-media img {
  border-radius: 0.4rem 0.4rem 2.4rem;
}
.fr-content-media__caption {
  padding-top: 0.8rem;
}
.fr-content-media__caption p {
  font-weight: 400;
  font-size: 1.4rem;
}

.highlighted-block {
  margin: 1.6rem 0 4.8rem;
  position: relative;
  padding-left: 2.4rem;
}
@media (max-width: 767.98px) {
  .highlighted-block {
    margin: 1.6rem 0 2.4rem;
  }
}
.highlighted-block::before {
  content: "";
  height: 1.6rem;
  width: 1.6rem;
  position: absolute;
  border-bottom: 0.2rem solid var(--ecume-360);
  left: 0;
  top: 0;
  display: block;
}
@media (max-width: 767.98px) {
  .highlighted-block::before {
    height: 1.2rem;
  }
}

.text-image.block {
  margin-top: 6.4rem;
}
@media (max-width: 767.98px) {
  .text-image.block {
    margin-top: 3.2rem;
  }
}
.text-image.block-bg-dark {
  padding: 13.6rem 0;
}
.text-image.block-bg-dark.block {
  margin: 0;
  padding-top: 6.4rem;
}
@media (max-width: 767.98px) {
  .text-image.block-bg-dark.block {
    padding-top: 3.2rem;
  }
}
.text-image.block-bg-dark .card-inca {
  background: var(--ecume-125);
}
.text-image.block-bg-dark .card-inca * {
  color: var(--bs-white);
}
.text-image.text-image-w-podcast.block-bg-dark .btn-link:hover, .text-image.text-image-w-podcast.block-bg-dark .btn-link:focus {
  color: var(--bs-white);
}
.text-image.text-image-w-podcast.block-bg-dark .btn-link:hover .material-symbols-outlined, .text-image.text-image-w-podcast.block-bg-dark .btn-link:focus .material-symbols-outlined {
  background: var(--ecume-200);
  border-radius: 50%;
}
@media (min-width: 992px) {
  .text-image.text-image-w-podcast .card-inca {
    margin-top: 1.6rem;
  }
}
.text-image.text-image-w-podcast .card-inca .card-body {
  padding: 1.4em 2.4rem 4rem 0;
}
@media (max-width: 991.98px) {
  .text-image.text-image-w-podcast .card-inca .card-body {
    padding: 2.4em 0 1.6rem 2.2rem;
  }
}
@media (max-width: 991.98px) {
  .text-image.text-image-w-podcast .podcast-wrapper {
    border-left: 0.2rem solid var(--ecume-360);
    margin-left: 1.6rem;
    padding: 0 0 0.8rem 2.4rem;
  }
}
.text-image.text-image-w-podcast .transcription {
  margin-top: 0.8rem;
}
.text-image.text-image-w-podcast .transcription .btn {
  margin-bottom: 1.6rem;
}
.text-image.text-image-w-podcast .text-image-img img {
  border-radius: 2.4rem 0.4rem;
}
@media (max-width: 767.98px) {
  .text-image .container {
    padding: 0;
  }
}
@media (max-width: 991.98px) {
  .text-image .container .row {
    flex-direction: column-reverse;
  }
}
.text-image .card-inca {
  --bs-border-radius: 0;
  border-top-width: 0.2rem;
  margin-top: 6rem;
}
@media (min-width: 1400px) {
  .text-image .card-inca {
    margin-right: -0.8rem;
  }
}
@media (max-width: 991.98px) {
  .text-image .card-inca {
    border-left-width: 0.2rem;
    border-top-width: 0;
    margin: 0 1.6rem;
  }
}
.text-image .card-inca .card-title {
  font-size: 4.8rem;
  line-height: 1.125;
}
@media (max-width: 1199.98px) {
  .text-image .card-inca .card-title {
    font-size: 3.8rem;
  }
}
@media (max-width: 767.98px) {
  .text-image .card-inca .card-title {
    font-size: 2.8rem;
    letter-spacing: 0.084rem;
  }
}
.text-image .card-inca .card-body {
  padding: 2.2rem 2.4rem 1.6rem;
}
@media (max-width: 767.98px) {
  .text-image .card-inca .card-body {
    padding: 2.4rem 0 2.4rem 2.2rem;
  }
}
.text-image-img {
  position: relative;
}
@media (min-width: 1400px) {
  .text-image-img {
    padding-left: 0.8rem;
  }
}
.text-image-img img {
  aspect-ratio: 1.2;
  border-radius: 0.4rem 0.4rem 2.4rem;
}
@media (max-width: 767.98px) {
  .text-image-img img {
    aspect-ratio: 1.27;
  }
}
.text-image .video-block {
  padding: 0;
}
@media (min-width: 1400px) {
  .text-image .video-block {
    padding-left: 0.8rem;
  }
}
.text-image .video-block-media {
  margin-bottom: 0 !important;
}
.text-image .video-block-media figure {
  margin: 0;
}

.frieze {
  padding: 6.4rem 0 0;
  position: relative;
}
@media (max-width: 767.98px) {
  .frieze {
    padding: 3.2rem 0 0;
  }
}
.frieze::before {
  background: var(--ecume-125);
  bottom: 0;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-50%);
  width: 0.2rem;
}
.block-bg-dark .frieze::before {
  background: var(--bs-white);
}
.frieze-wrapper {
  margin-bottom: 13.6rem;
}
@media (max-width: 767.98px) {
  .frieze-wrapper {
    margin-bottom: 6.4rem;
  }
}
.frieze-list li:nth-child(even) .frieze .col-text {
  order: 2;
}
@media (min-width: 768px) {
  .frieze-list li:nth-child(even) .frieze .col-text {
    padding: 0 0 0 3.2rem;
  }
}
.frieze-list li:nth-child(even) .frieze .col-image {
  order: 1;
}
@media (min-width: 768px) {
  .frieze-list li:nth-child(even) .frieze .col-image {
    padding: 0 0.8rem 0 0;
  }
}
@media (max-width: 767.98px) {
  .frieze-list li:first-child .frieze {
    padding: 6.4rem 0 0;
  }
}
.frieze-list li:last-child .frieze::before {
  bottom: -12rem;
}
@media (max-width: 767.98px) {
  .frieze-list li:last-child .frieze::before {
    bottom: -6.4rem;
  }
}
.frieze .col-text {
  padding: 0 3.2rem 0 0;
}
@media (max-width: 767.98px) {
  .frieze .col-text {
    order: 2;
    padding: 0;
  }
}
@media (max-width: 767.98px) {
  .frieze .col-text .card-inca {
    --bs-border-radius: 0 0 2.4rem .4rem;
  }
}
@media (max-width: 767.98px) {
  .frieze .col-text .card-body {
    padding: 1.4rem 1.6rem 4.8rem;
  }
}
.block-bg-dark .frieze .col-text .card-body * {
  color: var(--ecume-125);
}
@media (max-width: 767.98px) {
  .frieze .col-text .card-body .material-symbols-outlined:last-child {
    bottom: 1.4rem;
    right: 1.4rem;
  }
}
@media (max-width: 767.98px) {
  .frieze .col-text .card-title {
    font-size: 2.4rem;
  }
}
@media (max-width: 767.98px) {
  .frieze .col-text .card-end {
    font-size: 1.6rem;
  }
}
.frieze .col-image {
  padding: 0 0 0 0.6rem;
}
@media (max-width: 767.98px) {
  .frieze .col-image {
    order: 1;
    padding: 0;
  }
}
.frieze .col-image .frieze-img {
  position: relative;
}
.frieze .col-image .frieze-img img {
  aspect-ratio: 1.2;
  border-radius: 2.4rem 0.4rem;
}
@media (max-width: 767.98px) {
  .frieze .col-image .frieze-img img {
    border-radius: 2.4rem 0.4rem 0 0;
  }
}