@import '../../../../node_modules/plyr/src/sass/plyr';
@import '../../../../node_modules/plyr/src/sass/base';

.video {
    --plyr-color-main: var(--bs-primary);
    margin: 4rem 0;

    &__content {
        max-width: 88.8rem;
        position: relative;
    }

    &__img {
        position: relative;
        height: 100%;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: var(--bs-zodiac);
            opacity: .34;
        }

        picture {
            --bs-aspect-ratio: 56.25%;
            position: relative;
            width: 100%;
            height: 100%;

            &::before {
                display: block;
                padding-top: var(--bs-aspect-ratio);
                content: '';
            }
        }

        img {
            height: 100%;
            width: auto;
            max-width: none;
            transform: translate(-50%, -50%);
            position: absolute;
            top: 50%;
            left: 50%;
        }
    }

    .btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: max-content;
    }

    .plyr {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        &__control {
            &--overlaid {
                --plyr-control-icon-size: 2.2rem;
                background-color: transparent;
                border: .2rem solid var(--bs-white);
                width: 8rem;
                height: 8rem;
                display: flex !important;
                justify-content: center;
                align-items: center;
            }
        }

        iframe {
            width: 100%;
            height: 100%;
            display: block;
        }

        ~ .btn {
            display: none;
        }
    }
}
