.overlapping-text {
    &.block {
        margin-bottom: 6.4rem;

        @include media-breakpoint-down(md) {
            margin-bottom: 3.2rem;
        }
    }

    &.block-bg-dark {
        &.block {
            margin: 0;
            padding-bottom: 6.4rem;

            @include media-breakpoint-down(md) {
                padding-bottom: 3.2rem;
            }
        }

        .card {
            &-title {
                color: var(--ecume-125);

                a {
                    color: inherit;
                }
            }

            &-end, &-end *, &-start, &-start * {
                color: var(--ecume-125);
            }
        }
    }

    .container {
        @include media-breakpoint-down(md) {
            --bs-gutter-x: 0;
        }
    }

    [class*='col-'] {
        &.order-1 {
            @include media-breakpoint-up(md) {
                margin-right: -4.8rem;
            }

            @include media-breakpoint-down(md) {
                margin-bottom: -4.8rem;
            }
        }

        &.order-2 {
            position: relative;
            z-index: 1;
        }
    }

    &-img {
        position: relative;

        img {
            aspect-ratio: 1.2;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: .4rem .4rem 2.4rem;

            @include media-breakpoint-down(md) {
                border-radius: 2.4rem .4rem;
            }
        }
    }

    .card {
        @include media-breakpoint-down(md) {
            margin: 0 1.2rem;
        }
    }
}
