.highlighted-block {
    margin: 1.6rem 0 4.8rem;
    position: relative;
    padding-left: 2.4rem;

    @include media-breakpoint-down(md) {
        margin: 1.6rem 0 2.4rem;
    }

    &::before {
        content: '';
        height: 1.6rem;
        width: 1.6rem;
        position: absolute;
        border-bottom: .2rem solid var(--ecume-360);
        left: 0;
        top: 0;
        display: block;

        @include media-breakpoint-down(md) {
            height: 1.2rem;
        }

    }
}
