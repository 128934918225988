.title-block {
    margin: 13.6rem 0 4.8rem;

    @include media-breakpoint-down(md) {
        margin: 6.4rem 0 1.6rem;
    }

    &.block-bg-dark {
        margin: 0;
        padding-bottom: 4.8rem;
        padding-top: 13.6rem;

        @include media-breakpoint-down(md) {
            padding-bottom: 1.6rem;
            padding-top: 6.4rem;
        }

        .title-block-inner {
            &::after {
                background: var(--bs-white);
            }
        }
    }

    &-section {
        &.block-bg-dark {
            + div {
                margin-top: 0;
                padding-top: 0;

                .block-bg-dark {
                    padding-top: 0;
                }
            }
        }

        + div {
            margin-top: 0;

            &.landing-page__block {
                > div:first-child {
                    margin-top: 0;
                }
            }
        }
    }

    &-inner {
        gap: 1rem;

        @include media-breakpoint-down(md) {
            align-items: flex-end;
            gap: .4rem;
        }

        &::after {
            background: var(--ecume-125);
            content: '';
            height: .2rem;
            flex-grow: 1 ;
            margin: 2.4rem 0 0;

            @include media-breakpoint-down(md) {
                margin: 0 0 2rem;
            }
        }

        .title-block-item {
            line-height: normal;
            margin: 0 0 .6rem;
            max-width: 62%;

            @include media-breakpoint-down(md) {
                line-height: 1.3333;
                max-width: 75%;
            }
        }
    }
}
