.visual-insert {
    &-wrapper {
        .container {
            >  .row {
                gap: 6.4rem 0;

                @include media-breakpoint-up(md) {
                    --bs-gutter-x: 1.6rem;
                }

                @include media-breakpoint-down(md) {
                    gap: 2rem 0;
                }

                &.slick-slider {
                    display: flex;

                    .slick-list {
                        padding: 0 4rem 0 0 !important;
                    }

                    .slick-slide {
                        padding: 0 0 0 1.6rem;
                    }

                    [class$='-6'] {
                        .card-inca {
                            @include media-breakpoint-down(md) {
                                height: 43rem;
                            }
                        }
                    }
                }

                &.visual-insert-slider {
                    &:not(.slick-slider) {
                        @include media-breakpoint-down(md) {
                            display: flex;
                            flex-wrap: nowrap;
                            overflow: hidden;
                        }
                    }
                }

                [class$='-6'] {
                    .card-inca {
                        height: 54.6rem;

                        @include media-breakpoint-down(md) {
                            height: 27.3rem;
                        }
                    }
                }

                [class$='-4'] {
                    .card-inca {
                        height: 64.5rem;

                        @include media-breakpoint-down(md) {
                            height: 43rem;
                        }
                    }
                }
            }
        }

        .card-inca {
            &:hover, &:focus, &.has-focus {
                .card-img-overlay {
                    background: linear-gradient(0deg, rgba(34, 41, 64, .9) 59.01%, rgba(34, 41, 64, 0) 95.17%);
                }

                .card-end {
                    height: 100%;
                    overflow: initial;
                    transition: all .5s;
                }
            }

            .card {
                &-img-overlay {
                    background: linear-gradient(0deg, rgba(34, 41, 64, .8) 34.7%, rgba(34, 41, 64, 0) 68.22%);

                    @include media-breakpoint-down(md) {
                        --bs-card-img-overlay-padding: 1.6rem 1.6rem 2.4rem 1.6rem;
                    }
                }

                &-title {
                    margin-top: .8rem;

                    @include media-breakpoint-up(md) {
                        font-size: 2.4rem;
                    }
                }

                &-start {
                    line-height: 1.125;

                    p {
                        margin: 0;
                    }
                }

                &-end {
                    height: 0;
                    padding-top: .8rem;
                    overflow: hidden;
                    transition: all .5s;

                    @include media-breakpoint-down(md) {
                        display: none;
                    }
                }
            }
        }
    }
}
