.services-block {
    &-inner {
        padding: 13.6rem 0;

        @include media-breakpoint-down(lg) {
            padding: 6.4rem 0;
        }
    }

    &-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .services-full & {
            justify-content: flex-start;
        }
    }

    &-item {
        display: flex;
        align-items: center;
        margin-bottom: 3.2rem;
        width: 100%;
        position: relative;

        @include media-breakpoint-up(lg) {
            max-width: 50%;

            .services-full & {
                max-width: 33.333%;
            }

            &:nth-last-child(-n+2) {
                margin-bottom: 0;
            }
        }

        &__image {
            width: 8rem;
            flex-shrink: 0;
        }

        &__text {
            margin-left: 1.6rem;
            font-size: 1.8rem;
            font-weight: 800;
        }
    }

    &-push {
        border-top: 1rem solid var(--ecume-360);
        padding: 3.8rem 3.2rem 0 2.4rem;
        background-color: var(--bs-white);
        border-radius: .2rem .2rem 2.4rem;

        * {
            color: var(--ecume-125);
        }

        &__text {
            line-height: 1.6;
        }

        &__image {
            margin-left: .8rem;

            img {
                width: 100%;
            }
        }
    }
}
