.card {
    &-inca {
        --bs-card-border-color: var(--ecume-360);
        --bs-border-radius: .4rem .4rem 2.4rem .4rem;
        --bs-card-border-width: 0;
        border-top-width: 1rem;

        &:hover, &:focus {
            .material-symbols-outlined {
                &:last-child {
                    &.ibg {
                        background: var(--emeraude-900);
                    }

                    &.text-primary {
                        color: var(--ecume-125--hover) !important;
                    }
                }
            }
        }

        &-w-border {
            border-bottom-width: .2rem;
            border-left-width: .2rem;
            border-right-width: .2rem;

            .card {
                &-body {
                    padding: 3rem 2.4rem 3.8rem;

                    .material-symbols-outlined {
                        &:last-child {
                            bottom: 3.8rem;
                            position: absolute;
                            right: 2.4rem;
                        }
                    }
                }

                &-title {
                    padding-right: 3.2rem;
                }
            }
        }

        &-no-border {
            border-top: none;
            position: relative;

            &::after {
                right: 0;
                left: 0;
                top: 0;
                content: '';
                position: absolute;
                display: block;
                background: var(--ecume-360);
                height: .2rem;
            }

            &--bottom {
                &::after {
                    top: initial;
                    bottom: 0;
                }
            }
        }

        &-horizontal {
            @include media-breakpoint-up(md) {
                flex-direction: row;
            }

            .card {
                &-body {
                    align-self: center;
                    max-width: 100%;
                    order: 2;
                    padding: 2.4rem 2.4rem 7.2rem;

                    @include media-breakpoint-down(md) {
                        max-width: none;
                        padding: 2.4rem 1.6rem 6.2rem;

                        .list-articles-item & {
                            padding-bottom: 1.6rem;
                        }
                    }

                    .push & {
                        align-self: flex-start;
                    }

                    .list-articles-item & {
                        max-width: 100%;
                    }

                    .card-title {
                        font-size: 2.4rem;
                        margin: 0 0 1.6rem;
                        padding: 0;

                        @include media-breakpoint-down(md) {
                            font-size: 1.8rem;
                        }

                        .list-articles-item & {
                            @include media-breakpoint-up(lg) {
                                max-width: 73.7rem;
                            }
                        }
                    }

                    .card-end {
                        max-width: 73.7rem;

                        @include media-breakpoint-down(md) {
                            font-size: 1.4rem;

                            .block-bg-dark & {
                                font-size: 1.6rem;
                            }
                        }

                        .list-articles-item & {
                            @include media-breakpoint-up(lg) {
                                max-width: 73.7rem;
                            }
                        }

                        p {
                            margin: 0;
                        }
                    }

                    .material-symbols-outlined {
                        &:last-child {
                            &:not(.position-relative) {
                                bottom: 2.4rem;
                                right: 2.4rem;
                            }
                        }
                    }
                }

                &-header {
                    background: none;
                    order: 1;
                    padding: 0;

                    @include media-breakpoint-up(md) {
                        flex: 0 0 32.4rem;

                        .block-bg-dark & {
                            flex: 0 0 43.2rem;
                        }
                    }

                    .block-bg-dark & {
                        padding: 0 0 0 1.6rem;

                        @include media-breakpoint-down(md) {
                            padding: 0;
                        }
                    }

                    picture {
                        @include media-breakpoint-up(md) {
                            height: 100%;
                        }
                    }

                    img {
                        aspect-ratio: 1.5;
                        border-radius: 0 0 2.4rem;
                        min-height: 100%;
                        min-width: 100%;
                        object-fit: cover;
                        position: relative;
                        z-index: 1;

                        .block-bg-dark & {
                            border-radius: 0;
                        }
                    }
                }
            }

            &.card-inca-pub {
                .card-header {
                    img {
                        aspect-ratio: auto;
                        border-radius: 0;
                    }
                }

                &::after {
                    z-index: 1;
                }
            }
        }

        &-light {
            border-width: .2rem;
            min-height: 9.2rem;
            padding: 1.6rem;

            &:hover {
                background: var(--ecume-975);
            }

            &-wrapper {
                .row {
                    --bs-gutter-x: 1.8rem;
                    gap: 1.8rem 0;
                    margin: 0;

                    .row & {
                        margin: 0 -.9rem;
                    }
                }
            }

            &.card-inca {
                .card-body {
                    flex-direction: revert;
                    gap: 0 .8rem;
                    justify-content: space-between;
                    padding: 0;

                    .card-title {
                        font-size: 1.6rem;
                        font-weight: 700;
                        margin: 0;

                        a {
                            text-decoration: none;
                        }
                    }

                    .material-symbols-outlined:last-child {
                        align-self: flex-start;
                        margin: 0;
                    }
                }
            }
        }

        &-contact {
            margin: 6.4rem auto 0;
            max-width: 65.6rem;

            .card-body {
                padding: 1.4rem 2.2rem 2.2rem;

                @include media-breakpoint-down(md) {
                    padding: 1.4rem 1.4rem 2.2rem;
                }

                .row {
                    @include media-breakpoint-up(xl) {
                        --bs-gutter-x: 4rem;
                    }

                    &:not(:first-child) {
                        margin-top: 1.6rem;
                    }

                    [class*=col-] {
                        @include media-breakpoint-up(xl) {
                            max-width: 29.3rem;
                        }
                    }
                }

                .card-title {
                    font-size: 2.4rem;
                    order: initial;
                    margin: 0 0 1.5rem;
                    padding: 0;

                    @include media-breakpoint-down(md) {
                        font-size: 1.8rem;
                    }
                }

                .title {
                    font-size: 1.8rem;
                    font-weight: 800;
                    line-height: 1.444;
                    margin: 0;
                }

                .function {
                    margin: 0;

                    @include media-breakpoint-down(md) {
                        font-size: 1.4rem;
                        margin: 0 0 1.6rem;
                    }
                }

                .list-content {
                    display: flex;
                    flex-direction: column;
                    gap: .8rem 0;

                    li {
                        font-size: 1.6rem;
                        line-height: normal;
                        position: relative;
                        padding-left: 2rem;
                        word-wrap: break-word;
                        overflow-wrap: break-word;
                        word-break: break-all;

                        @include media-breakpoint-down(md) {
                            font-size: 1.4rem;
                        }

                        .incaicon {
                            font-size: 1.8rem;
                            margin-top: .4rem;
                            position: absolute;
                            left: 0;
                            top: 0;

                            @include media-breakpoint-down(md) {
                                margin-top: .2rem;
                            }
                        }
                    }
                }
            }
        }

        &-key-figure {
            --bs-card-border-color: var(--bs-white);
            --bs-card-bg: var(--ecume-360);
            background: var(--bs-ecume-360) !important;

            &-wrapper {
                border-radius: 2.4rem .4rem;
                background: var(--ecume-360);
                padding: 2.4rem;

                @include media-breakpoint-down(md) {
                    padding: 1.6rem;
                }
            }

            .card {
                &-body {
                    padding: 3rem 2.2rem;

                    @include media-breakpoint-down(md) {
                        padding: 2.2rem 2.2rem 3rem;
                    }
                }

                &-title, &-start {
                    color: var(--bs-white);
                }

                &-title {
                    display: flex;
                    flex-direction: column;

                    .num {
                        font-size: 6.4rem;
                        letter-spacing: .192rem;
                        line-height: 1.25;
                    }

                    .text {
                        font-size: 3.2rem;
                        line-height: normal;

                        @include media-breakpoint-down(md) {
                            font-size: 2.4rem;
                            line-height: 1.333;
                        }
                    }
                }

                &-start {
                    p {
                        line-height: 1.286;
                        margin: 0 0 1.6rem;

                        @include media-breakpoint-down(md) {
                            font-size: 1.2rem;
                            margin: 0 0 .8rem;
                        }
                    }
                }
            }
        }

        .card {
            &-img-overlay {
                --bs-card-img-overlay-padding: 2.4rem;
                align-items: flex-end;
                background-image: linear-gradient(180deg, rgba(var(--ecume-125-rgb), .05) 16.47%, rgba(var(--ecume-125-rgb), .8) 62.21%);
                border-radius: 0 0 2.4rem .4rem;
                display: flex;

                @include media-breakpoint-down(md) {
                    --bs-card-img-overlay-padding: 1.6rem 1.4rem 2.2rem 1.4rem;
                }

                + picture {
                    border-radius: 0 0 2.4rem .4rem;
                    height: 100%;
                    overflow: hidden;

                    img {
                        min-height: 100%;
                        min-width: 100%;
                        object-fit: cover;
                    }
                }

                .card-body, .card-body * {
                    color: var(--bs-white);
                }

                .card {
                    &-body {
                        padding: 0;

                        .material-symbols-outlined:last-child {
                            @include media-breakpoint-down(md) {
                                margin-top: .8rem
                            }
                        }
                    }

                    &-title {
                        @include media-breakpoint-down(md) {
                            font-size: 1.6rem;
                            margin-top: .8rem
                        }

                        a {
                            @include media-breakpoint-down(md) {
                                display: block;
                                line-height: 1.1875;
                            }
                        }
                    }

                    &-text {
                        @include media-breakpoint-down(md) {
                            font-size: 1.4rem;
                            line-height: 1.57;
                            margin-top: .8rem
                        }
                    }
                }
            }

            &-body {
                align-items: flex-start;
                display: flex;
                flex-direction: column;

                .material-symbols-outlined {
                    &:last-child {
                        &:not(.position-relative) {
                            align-self: flex-end;
                            color: var(--ecume-125);
                            margin-top: 1.6rem;
                            order: 4;
                            width: 2.4rem;
                        }
                    }
                }

                .btn {
                    margin: 1.6rem 0 0 auto;
                    order: 4;

                    .block-bg-dark & {
                        color: var(--ecume-125);

                        &:hover, &:focus {
                            --bs-btn-hover-border-color: var(--ecume-125--hover);
                            color: var(--ecume-125--hover);
                        }
                    }
                }
            }

            &-title {
                font-size: 1.8rem;
                font-weight: 800;
                line-height: normal;
                margin: 1.6rem 0 0;
                order: 2;

                a {
                    text-decoration: none;
                }
            }

            &-text {
                margin: 1.6rem 0 0;
                order: 3;
            }

            &-start {
                align-items: center;
                display: flex;
                font-size: 1.4rem;
                justify-content: space-between;
                order: 1;
                width: 100%;

                @include media-breakpoint-down(md) {
                    font-size: 1.2rem;
                }

                .tags-group {
                    display: flex;
                    flex-wrap: wrap;
                    gap: .8rem;
                    line-height: normal;
                    margin: 0 0 1.6rem;

                    .tag {
                        margin: 0;

                        @include media-breakpoint-down(md) {
                            font-size: 1.2rem;
                        }
                    }
                }
            }

            &-end {
                order: 4;
            }

            &-header {
                &.full-img {
                    picture {
                        width: 100%;
                    }

                    img {
                        width: 100%;
                        object-fit: cover;
                        max-height: 27.6rem;
                    }
                }
            }

            &-text-icon {
                .incaicon {
                    flex-shrink: 0;
                    margin-right: .8rem;
                    font-size: 2rem;
                }
            }

            &-type-badge {
                border-radius: .4rem .4rem .8rem 0;
                background: var(--ecume-125);
                color: var(--bs-white);
                padding: .8rem;
            }
        }

        &-hover {
            border-radius: .4rem;

            &:focus-within, &:hover, &.active {
                background: var(--ecume-975);
            }
        }

        &-call {
            .card-body {
                .material-symbols-outlined:last-child {
                    &:not(.position-relative) {
                        float: right;

                        @include media-breakpoint-up(lg) {
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            top: 0;
                            margin: auto;
                            height: 2.4rem;
                        }
                    }

                }
            }
        }

        .popover & {
            font-size: 1.4rem;
            min-height: 0!important;

            p {
                margin-bottom: 0!important;
            }
        }

        .text {
            &.site {
                width: calc(100% - 3.3rem);
            }
        }

        &-source {
            font-size: 1.4rem;
        }

        &-trial {
            .list-unstyled {
                @include media-breakpoint-up(lg) {
                    width: 80%;
                }

                li {
                    width: 100%;

                    @include media-breakpoint-up(lg) {
                        width: 50%;
                        padding-right: 2rem;
                    }
                }
            }
        }

        &-pub {
            min-height: 34.3rem;

            .card-header {
                picture {
                    margin: 0 auto;
                }

                img {
                    border-radius: 0;
                    width: auto;
                    min-width: 0;
                    min-height: 0;
                }
            }

            .card-body {
                padding: 2.4rem;
                align-self: flex-start;
            }

            .card-pub-links {

                @include media-breakpoint-up(lg) {
                    text-align: right;
                }
                a {
                    text-decoration: none;
                }
            }
        }
    }

    &-notice {
        .card-title {
            order: unset
        }
    }

    &-top-block {
        font-size: 1.4rem;
        margin-bottom: 1.6rem;

        @include media-breakpoint-down(md) {
            font-size: 1.2rem;
            margin-bottom: .8rem;
        }
    }

    &-top-subtitle {
        margin-right: -4.8rem !important;
        text-align: right;

        @include media-breakpoint-down(md) {
            margin-right: 0 !important;
            text-align: left;
        }
    }
}

.card-fake-btn-absolute {
    padding-bottom: 1rem;

    @include media-breakpoint-up(lg) {
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        margin: auto;
        height: 2.4rem;
        padding-bottom: 0;
    }
}
